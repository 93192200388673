import React from "react"
import StatusFilterRow from "./StatusFilterRow"

class StatusFilter extends React.Component {

  constructor(props) {
    super(props);
    this.state = { filterRows: this.props.existingFilters || [], fieldName: this.props.fieldName || 'status_filters' };
  }

  componentDidMount() {
    window.initChoices('.fancy-select');
  }

  handleChange(e) {
    
  }

  addRow() {
    let _rows = this.state.filterRows;
    _rows.push({ name: '', gte: '', lte: '', location: ''});

    this.setState({ filterRows: _rows }, function() {
      window.initChoices('.fancy-select');
    });
  }

  render() {
    return (
      <div>
        
        {this.state.filterRows.map(function(filterRow, index) {
          return(<StatusFilterRow key={index} fieldName={this.state.fieldName} filterRow={filterRow} statuses={this.props.statuses} locations={this.props.locations}></StatusFilterRow>);
        }.bind(this))}
        
        <div className="row">
          <span className="btn -color-green -size-small -margin-right_4 -margin-top -icon -icon-left" onClick={this.addRow.bind(this)}>
            <i className='fa fa-filter'></i>
            Add New Filter
          </span>
        </div>
      </div>
    )
  }

}

export default StatusFilter;