import React from "react"
import PropTypes from "prop-types"
import ReactDOM from 'react-dom'

import C3Chart from 'react-c3js';
import 'c3/c3.css';

class Chart extends React.Component {


  constructor(props) {
    super(props);

    this.state = { data: this.props.data };
  }


  // componentDidMount() {
  //   this.generateChart();
  // }

  // componentDidUpdate(prevProps, prevState, snapshot) {
  //   if (!(JSON.stringify(prevProps.config) === JSON.stringify(this.props.config))) {
  //     this.generateChart();
  //   } else {
  //     this.chart.flush();
  //   }
  // }

  // componentWillUnmount() {
  //   this.chart = this.chart.destroy()
  // }

  formatTooltip() {
    var format = this.props.format;
    var formatTooltip = {};
    if (format) {
      formatTooltip = {
        tooltip: {
          format: {
            value: function (value, ratio, id, index) {
              var f = d3.format(format);
              return f(value);
            }
          }
        }
      }
    }
    return formatTooltip;
  }

  // generateChart() {
  //   this.chart = this.c3.generate({
  //     bindto: ReactDOM.findDOMNode(this),
  //     ...this.formatTooltip(),
  //     ...this.props.config,
  //   })
  // }

  render() {
    return (
      <div style={{height: '300px'}}>
        <C3Chart size={{ heigth: '300px' }} {...this.props} />
      </div>
    )
  }

}

export default Chart;
