import React from "react";
import Modal from 'react-modal';
import DatePicker from 'react-datepicker';
import moment from 'moment';

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    overflow              : 'visible',
    padding               : '0'
  }
};
Modal.setAppElement('body')

class TaskEditor extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false,
      scheduledDate: moment(this.props.scheduled_date),
      dueDate: this.props.due_date ? moment(this.props.due_date) : moment()
    };
    this.TOKEN = null;
    this.csrfToken = "";
  }

  componentDidMount(){
    this.csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
  }


  openModal() {
    this.setState({modalIsOpen: true});
  }

  closeModal() {
    this.setState({modalIsOpen: false});
  }

  handleScheduledDateChange(date) {
    this.setState({ scheduledDate: date});
  }

  handleDueDateChange(date) {
    this.setState({ dueDate: date});
  }

  render() {
    return (
      <div className="task-creator">

        <span href="#" onClick={this.openModal.bind(this)} title="Edit Task" className='-variation-empty btn -size-normal -icon -icon-only -color-gray_darker -margin-left_3'>
          <svg x="0px" y="0px" width="24px" height="24px" viewBox="0 0 24 24" enableBackground="new 0 0 24 24">
            <g id="Bounding_Boxes">
              <g id="ui_x5F_spec_x5F_header_copy_3"></g>
              <path fill="none" d="M0,0h24v24H0V0z"/>
            </g>

            <g id="Outline_1_">
              <g id="ui_x5F_spec_x5F_header_copy_4"></g>
              <path fill="#000" id="XMLID_37_" d="M14.06,9.02l0.92,0.92L5.92,19H5v-0.92L14.06,9.02 M17.66,3c-0.25,0-0.51,0.1-0.7,0.29l-1.83,1.83
                l3.75,3.75l1.83-1.83c0.39-0.39,0.39-1.02,0-1.41l-2.34-2.34C18.17,3.09,17.92,3,17.66,3L17.66,3z M14.06,6.19L3,17.25V21h3.75
                L17.81,9.94L14.06,6.19L14.06,6.19z"/>
            </g>
          </svg>
        </span>
        {/*<button onClick={this.openModal.bind(this)} disabled={this.state.uuids.length == 0} className='btn -color-ink'>Create Task</button>*/}
        <Modal contentLabel="Example Modal" isOpen={this.state.modalIsOpen} onRequestClose={this.closeModal.bind(this)} style={customStyles}>
          <div className="task-modal-header">
            <h2 className="task-modal-title">Edit PlanME - Task</h2>
          </div>

          <form className="form" style={{width: 500}} action={this.props.url} acceptCharset="UTF-8" method="post">
            <div className="task-modal-content">
              <input type="hidden" name="authenticity_token" value={this.csrfToken} />
              <input type="hidden" name="_method" value="PATCH" />

              <input type="hidden" name="task[assigned_by_id]" value={this.props.assigned_by}/>

              <p className="-variation-uppercase -line-reset -margin-top_2 -margin-bottom_3 -weight-regular -size-small">Assign to</p>
              <select name="task[assigned_to_id]" className="form-control select" defaultValue={this.props.assigned_to}>
                <option value="">None</option>
                {this.props.users.map((user, index) => {
                  return <option key={index} value={user.id}>{user.first_name} {user.last_name}</option>;
                })}
              </select>

              <p className="-variation-uppercase -line-reset -margin-top_2 -margin-bottom_3 -weight-regular -size-small">Description</p>
              <textarea name="task[description]" className="form-control" rows="7" defaultValue={this.props.description}></textarea>

              <div className="row">
                <div className="cell-sm-6">
                  <p className="-variation-uppercase -line-reset -margin-top_2 -margin-bottom_3 -weight-regular -size-small">Schedule</p>
                  <DatePicker selected={this.state.scheduledDate} onChange={this.handleScheduledDateChange.bind(this)} className="form-control" defaultValue={this.props.scheduled_date} />
                  <input type="hidden" name="task[scheduled_date]" value={moment(this.state.scheduledDate).format('YYYY-MM-DD')} />
                </div>

                <div className="cell-sm-6">
                  <p className="-variation-uppercase -line-reset -margin-top_2 -margin-bottom_3 -weight-regular -size-small">Due</p>
                  <DatePicker selected={this.state.dueDate} onChange={this.handleDueDateChange.bind(this)} className="form-control" defaultValue={this.props.due_date} />
                  <input type="hidden" name="task[due_date]" value={moment(this.state.dueDate).format('YYYY-MM-DD')} />
                </div>
              </div>

              <p className="-variation-uppercase -line-reset -margin-top_2 -margin-bottom_3 -weight-regular -size-small">Preliminary Approval</p>
              <select name="task[reviewer_id]" className="form-control select">
                {this.props.reviewers.map((user, index) => {
                  return <option key={index} value={user.id}>{user.first_name} {user.last_name}</option>;
                })}
              </select>
            </div>

            <div className="row -margin-top align-middle align-right modal-buttons">
              <button onClick={this.closeModal.bind(this)} className="modal-button modal-button-cancel" >Cancel</button>
              <input type="submit" name="commit" value="Save" className="modal-button modal-button-save" data-disable-with="Save" />
            </div>
          </form>
        </Modal>
      </div>
    )
  }

}

export default TaskEditor;
