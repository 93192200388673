// Core
// 
import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['stampDate', 'stampPrice'];

  stampDateTarget: any;
  stampPriceTarget: any;

  initialize() { }

  connect() { }

  checkStamp(event: any) {
    this.toggleDate(event)
    this.togglePrice(event);
  }

  private toggleDate(event: any) {
    if (event.target.value != 'warranty_issue' /* Issue doesn't get a Date */) {
      const date: Date = new Date(this.stampDateTarget.dataset.date);

      this.stampDateTarget.disabled = false;
      // this.stampDateTarget.value = `${ date.getFullYear() }-${ date.getMonth() }-${ date.getDay() }`;
      this.stampDateTarget.value = date.toLocaleString();
    } else {
      this.stampDateTarget.value = '';
      this.stampDateTarget.disabled = true;
    }
  }

  private togglePrice(event: any) {
    const blacklist: any = ['anew', 'warranty_issue', 'issue_resolved'];
    
    if (blacklist.includes(event.target.value)) {
      this.stampPriceTarget.disabled = true;
      this.stampPriceTarget.value = '';
    } else {
      this.stampPriceTarget.disabled = false;
      this.stampPriceTarget.value = '';
    }
  }

  disconnect() { }
}