import React from 'react';
import { render } from "react-dom";
let HtmlToReact = require('html-to-react');
let HtmlToReactParser = require('html-to-react').Parser;

class StoreMetrics extends React.Component {
  
  constructor(props) {
    super(props);
    
    this.state = {
      loading: true,
      metrics: null
    }

  }

  componentDidMount() {
    this.loadMetrics();
  }


  loadMetrics(clearCaches=false) {
    let self = this;
    let url = this.props.refreshMetricsUrl;

    if (clearCaches) {
      let separator = '?';
      if (this.props.refreshMetricsUrl.indexOf('?') > -1) {
        separator = '&';
      }

      url = `${this.props.refreshMetricsUrl}${separator}clear=1`;
    }

    fetch(url)
      .then(response => response.text())
      .then(result => {
        //let doc = new DOMParser().parseFromString(result, "text/html");
        let htmlToReactParser = new HtmlToReactParser();
        let reactElement = htmlToReactParser.parse(result);

        self.setState({ metrics: reactElement, loading: false });
      });
  }

  refreshMetrics() {
    this.setState({ loading: true }, function() {
      this.loadMetrics(true);
    }.bind(this));
  }

  renderMetrics() {
    return (
      <div className="row align-top">
        {this.state.metrics}
      </div>
    );
  }

  renderLoader() {
    return (
      <div>
        <div className="metrics-loader row -margin-top -margin-bottom">
          <div className="cell-sm"></div>
          <span className="material-icons -color-gray_dark" style={{ fontSize: '4rem' }}>loop</span>
          <div className="cell-sm"></div>
        </div>

        <div className="row">
          <div className="cell-sm">
            <p className='-align-center'>This may take a few moments so please be patient</p>
            <p className='-align-center -color-gray_dark'><em>Clicking the Refresh Metrics button again will only slow it down</em></p>
          </div>
        </div>
      </div>
    );
  }

  render() {
    let toRender = this.state.loading ? this.renderLoader() : this.renderMetrics();
    // let toRender = this.renderLoader();
    return (
      <div className="store-metrics-loader">
        <div className="row -margin-bottom">
          <div className="cell-sm"></div>
          <div className="cell-sm-auto">
            <span className="button-one -color-white clickable" style={{ background: '#729acd'}}>
              <span className="-color-white material-icons -margin-right_3">loop</span>
              <span className="-weight-medium" onClick={this.refreshMetrics.bind(this)}>Refresh Metrics</span>
            </span>
          </div>
        </div>
        {toRender}
      </div>
    );
  }
}

export default StoreMetrics;