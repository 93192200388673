import React from 'react';
import PubSub from 'pubsub-js';

import { addClass, removeClass, hasClass } from '../dom-helper';

const TOPIC = 'reuse.item.selector';

let COUNTER = 0;

class ReuseItemSelectAll extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      selectedItems: []
    }
  }

  componentDidMount(){
    this.TOKEN = PubSub.subscribe(TOPIC, (msg, data) => {

      let _selectedItems = this.state.selectedItems;

      if (data.selected) {
        _selectedItems.push(data.uuid);
      } else {
        let position = _selectedItems.indexOf(data.uuid);
        if (position > -1) {
          _selectedItems.splice(index, 1);
        }
        
      }


      this.setState({ selectedItems: _selectedItems });
      
    });
  }

  componentWillUnMount() {
    PubSub.unsubscribe(this.TOKEN);
  }


  render() {
    let showButton = (this.state.selectedItems.length > 0);

    let addItemUrl = this.props.url;
    if (this.state.selectedItems.length > 0) {
      addItemUrl += '?';
      for (let i = 0; i < this.state.selectedItems.length; i++) {
        addItemUrl += "ids[]=" + this.state.selectedItems[i] + "&"; 
      }
    }
    return (
      <div className=''>
        {
          showButton &&
          <a className='btn' href={addItemUrl} style={{ backgroundColor: '#6da1d1' }} data-method= 'put'>
            <span className='-color-white'>Add selected</span>
          </a>
        }
      </div>
    )
  }
}

export default ReuseItemSelectAll;
