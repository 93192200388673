import React from 'react';

class UserManagement extends React.Component {


  constructor(props) {
    super(props);

    this.state = {
      management: this.props.userManagement,
      currentClientId: this.props.userManagement.client_id
    }
  }

  handleRemoveManagement() {
    this.props.removeManagement(this.props.position);
  }

  setCurrentClient(e) {
    let clientId = e.currentTarget.value;
    this.setState({ currentClientId: clientId });
  }

  getCurrentClient() {
    let cc = null;
    for (let i = 0; i < this.props.clients.length; i++) {
      let client = this.props.clients[i];
      if (client.object.id == this.state.currentClientId) {
        cc = client;
        break;
      }
    }

    return cc;
  }

  render() {
    if (!this.state.management.destroy) {

      let roles = this.props.globalRoles;
      let cc = this.getCurrentClient();

      if (cc) {
        roles = roles.concat(cc.roles);
      }

      return (
        <div className="row align-middle pill -padding_2 -margin-top_6">
          <div className="cell-sm">
            {this.state.management.id &&
              <input name="user[managements_attributes][][id]" type="hidden" value={this.state.management.id} />
            }
            <label>Client</label>
            <select name={"user[managements_attributes][][client_id]"} className="form-control" onChange={this.setCurrentClient.bind(this)} defaultValue={this.state.management.client_id}>
              {this.props.clients.map(function(client, index){
                return <option key={index} value={client.object.id}>{client.object.name}</option>
              }.bind(this))}
            </select>
           </div>
           <div className="cell-sm-4 -margin-x_2">
             <label>Role</label>
            <select name={"user[managements_attributes][][role_id]"} className="form-control" defaultValue={this.state.management.role_id}>
              {roles.map(function(role, index){
                return <option key={index} value={role.id}>{role.name}</option>
              }.bind(this))}
            </select>

            <input name={"user[managements_attributes][][user_id]"} type="hidden" value={this.state.management.user_id} />
          </div>

          <div className="cell-sm-auto">
            <span className="btn -variation-empty -size-medium -color-red -icon -icon-only" onClick={ this.handleRemoveManagement.bind(this) }>
              <i className="fa fa-trash-alt"></i>
            </span>
          </div>
        </div>
      );
    } else {
      return (
        <div className="row">
          <input name="user[managements_attributes][][id]" type="hidden" value={this.state.management.id} />
          <input name="user[managements_attributes][][_destroy]" type="hidden" value="1" />
        </div>
      )
    }
  }

}

export default UserManagement;