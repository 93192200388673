import React from 'react';
import Modal from 'react-modal';


const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    overflow              : 'visible',
    padding               : '0'
  }
};
Modal.setAppElement('body')

class ItemSelectAll extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      modalIsOpen: false
    }
  }

  openModal() {
    this.setState({modalIsOpen: true});
  }

  closeModal() {
    this.setState({modalIsOpen: false});
  }

  render() {
    

    return (
      <div>
        <span className='btn' style={{ backgroundColor: '#6da1d1' }} onClick={this.openModal.bind(this)}>
          <span className='-color-white'>Select All</span>
        </span>

        <Modal contentLabel="Example Modal" isOpen={this.state.modalIsOpen} onRequestClose={this.closeModal.bind(this)} style={customStyles}>
          <div className="task-modal-header">
            <h2 className="task-modal-title">All Items Selected</h2>
          </div>
          <div className="task-modal-content">
            <div className="row -margin-top_2">
              {
                this.props.addToReuseMePath &&
                <a href={this.props.addToReuseMePath} data-method= 'put' className='cell-sm-12 -padding_2 -margin-y_3 align-middle btn -variation-round -text-center -color-task -margin-bottom_2'>
                  <span className="-color-white">Add all to Active ReuseME</span>
                </a>
              }

              {
                this.props.editItemPath &&
                <a href={this.props.editItemPath} className='cell-sm-12 -padding_2 -margin-y_3 align-middle btn -variation-round -text-center -color-task -margin-bottom_2'>
                  <span className="-color-white">Bulk Edit</span>
                </a>
              }

              {
                this.props.bulkEditItemPath &&
                <a href={this.props.bulkEditItemPath} className='cell-sm-12 -padding_2 -margin-y_3 align-middle btn -variation-round -text-center -color-task -margin-bottom_2'>
                  <span className="-color-white">Bulk Edit Projects</span>
                </a>
              }

              {
                this.props.newTaskPath &&
                <a href={this.props.newTaskPath} className='cell-sm-12 -padding_2 -margin-y_3 align-middle btn -variation-round -text-center -color-asset'>
                  <span className="-color-white">New Work Request</span>
                </a>
              }
            </div>

          </div>
          <div className="row -margin-top align-middle align-right modal-buttons">
            <button onClick={this.closeModal.bind(this)} className="modal-button modal-button-cancel" >Cancel</button>
          </div>
        </Modal>

      </div>
    )
  }
}

export default ItemSelectAll;
