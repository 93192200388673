// Core
// 
import { Controller } from 'stimulus';

// Helpers
// 
import { addClass, removeClass, hasClass } from '../../../dom-helper';

// Plugins
// 
import axios, { AxiosResponse } from 'axios';
import TingleModal from 'tingle.js';

export default class extends Controller {
  handlerTarget: any;
  handlerTargets: any[];

  usersTarget: any;
  usersTargets: any[];

  static targets = ['handler', 'users'];

  connect() { }

  set(event: any) {
    const element: any = event.srcElement,
          userID: string = element.value;

    axios.get(event.currentTarget.dataset.url, {
      params: {
        user_id: userID
      }
    }).then((response: AxiosResponse<any>) => {

      const row: any = document.createElement('div');
            row.innerHTML = response.data;

      const selectedOption: any = Array.from(element.options).find((option: any) => option.id === userID);
            selectedOption.remove();

      this.usersTarget.appendChild(row);
    }).catch((error: any) => { });  
  }

  disconnect() { }
}