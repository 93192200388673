import React from 'react';
import PubSub from 'pubsub-js';

const TOPIC = 'store_levels';

class StoreLevelsField extends React.Component {


  constructor(props) {
    super(props);

    this.state = {
      levels: (props.levels || 0)
    }
  }

  handleChange(event) {
    let value = event.target.value;

    this.setState({levels: value}, function() {
      PubSub.publish(TOPIC, {
        levels: value
      });
    });
  }


  render() {
    return (
      <input name='location[levels]' className="form-control" min="0" max="10" type="number" defaultValue={this.state.levels} onChange={this.handleChange.bind(this)} />
    )
  }

}

export default StoreLevelsField;
