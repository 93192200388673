// Core
// 
import { Controller } from 'stimulus';

// Helpers
// 
import { addClass, removeClass, hasClass } from '../../../dom-helper';

// Plugins
// 
import axios, { AxiosResponse } from 'axios';
import Uppy from '@uppy/core';

import Dashboard from '@uppy/dashboard';
import FileInput from '@uppy/file-input';
import DragDrop from '@uppy/drag-drop';
import XHRUpload from '@uppy/xhr-upload';
import GoogleDrive from '@uppy/google-drive';
import Dropbox from '@uppy/dropbox';

export default class extends Controller {
  static targets = ['floorplan', 'manager'];

  // https://github.com/stimulusjs/stimulus/issues/121
  // 
  managerTarget: any;

  connect() {
    this.initializeUppy();
  }

  initializeUppy() {
    const uppy = Uppy({
      debug: true,
      autoProceed: false,
      restrictions: {
        maxFileSize: 5000000,
        maxNumberOfFiles: 10,
        minNumberOfFiles: 1,
        allowedFileTypes: ['image/*', '.pdf']
      }
    });

    uppy.use(Dashboard, {
      trigger: '[data-uppy]',
      inline: false,
      note: 'Images and PDF only, 1–10 files at a time, up to 50 MB',
      maxHeight: 250,
      metaFields: [
        { id: 'floorplan[license]', name: 'License', placeholder: 'specify a license if there is one' },
        { id: 'floorplan[caption]', name: 'Caption', placeholder: 'shortly, describe the floorplan' }
      ],
      proudlyDisplayPoweredByUppy: false,
      plugins: ['XHRUpload', 'FileInput']
    });
    
    uppy.use(FileInput, {});
    uppy.use(DragDrop, { target: 'body' });
    // uppy.use(GoogleDrive, { host: '' });
    // uppy.use(Dropbox, { host: '' })

    const token: any = document.head.querySelector("[name='csrf-token']"); 

    uppy.use(XHRUpload, {
      endpoint: this.managerTarget.dataset.uppyUri,
      formData: true,
      headers: {
        'X-CSRF-Token': token.content
      },
      fieldName: 'floorplan[file]'
    })

    uppy.run()

    uppy.on('complete', (result) => {
      // console.log('successful files:', result.successful)
      // console.log('failed files:', result.failed)
    })
  }
}