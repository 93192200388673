import React from "react"

class SettingCheckboxes extends React.Component {

  constructor(props) {
    super(props);
    this.state = { checkboxes: this.props.checkboxes };
  }


  handleClick(e) {

  }

  renderCheckbox(checkbox, index) {
    return (
      <div>

      </div>
    );
  }

  render() {
    return (
      <div>
        <div>
          <input type="checkbox" name={this.props.setting + "[all]"} onClick={this.handleClick.bind(this)} />
        </div>
        {this.state.checkboxes.map(function(checkbox, index) {
          return renderCheckbox(checkbox, index);
        }.bind(this))}
      </div>
    );
  }

}

export default SettingCheckboxes;