export function removeClass(element, className){
  if (element.classList) {
    element.classList.remove(className)
  } else {
    element.className = element.className
      .replace(new RegExp('(^|\\s)' + className + '(?:\\s|$)', 'g'), '$1')
      .replace(/\s+/g, ' ')
      .replace(/^\s*|\s*$/g, '');
  }
}

export function hasClass(element, className) {
  if (element.classList) {
    return !!className && element.classList.contains(className)
  } else {
    return `${ element.className }`.indexOf(`${ className }`) !== -1
  }
}

export function addClass(element, className){
  if (element.classList) {
    element.classList.add(className)
  } else if (!hasClass(element)) {
    element.className = element.className + ' ' + className
  }
}

export function findParent(element, parent) {
  while (element.parentNode) {
    element = element.parentNode;
    
    if (element.matches(parent)) {
      return element
    }
  }
  
  return null
}

// export function { addClass, removeClass, hasClass }
// export default { addClass, removeClass, hasClass }