import React from 'react';
import ReactDOM from 'react-dom';
import RequestPromise from 'request-promise-native';

import { ReactSVGPanZoom } from 'react-svg-pan-zoom';
import { AutoSizer } from 'react-virtualized';

import iziToast from 'izitoast';

class BaseFloorplan extends React.Component {

  constructor(props) {
    super(props);
    this.Viewer = null;
    this.centered = false;

    this.state = { hideFloorplan: !!this.props.hideFloorplan }

  }

  handleItemClick(event, element) {
    if (this.props.onItemClick) {
      this.props.onItemClick(event, element);
    }
  }

  handleFloorplanClick(event) {
    if (this.props.onFloorplanClick) {
      this.props.onFloorplanClick(event);
    }
  }

  setViewer(Viewer) {
    this.Viewer = Viewer;
    if (!this.centered) {
      this.Viewer.fitToViewer();
      this.centered = true;
    }
  }

  render() {
    let width = (document.getElementById("flooplan-holder").offsetWidth - 10)
    let height = this.props.height;

    if (this.state.hideFloorplan) {
      height = '30px';
    }

    return (
      <div style={{ width: width, height: height }}>
        {!this.state.hideFloorplan &&
          <AutoSizer>
            {(
              ({ width, height }) => width === 0 || height === 0 ? null : (
                <ReactSVGPanZoom width={ width } height={ height } x='0' y='0' ref={ Viewer => Viewer && this.setViewer(Viewer) } tool={ 'auto' } detectAutoPan={false} onClick={this.handleFloorplanClick.bind(this)}>
                  <svg width={ Number(this.props.floorplanWidth) } height={ Number(this.props.floorplanHeight) } className={''}>
                    <image href={ this.props.planImage } x='0' y='0' width={ this.props.floorplanWidth } height={ this.props.floorplanHeight } />
                    <svg id='floorplanItemsSVG' width={ this.props.floorplanWidth } height={ this.props.floorplanHeight } className='with-highlight'>
                      {
                        this.props.items.map(function(el, i) {
                          if (!el.toRemove) {
                            let stroke = {};
                            
                            if (el.id == this.props.selectedItem.id) {
                              stroke = { stroke: '#50b83c', strokeWidth: '2px' };
                            }

                            let colour = el.color;

                            if (this.props.heatMap && this.props.heatMapColour) {
                              colour = this.props.heatMapColour(el);
                            }

                            return(<circle className={''} style={{ cursor: 'pointer' }} key={ i } cx={ el.x } cy={ el.y } r='5' fill={colour} {...stroke} data-thumbnail={"el.thumbnail"} data-uuid={el.uuid} data-code={el.code} data-id={el.id} onClick={(event) => { this.handleItemClick(event, el)}} />)
                          } else {
                            return null;
                          }
                        }.bind(this))
                      }
                    </svg>
                  </svg>
                </ReactSVGPanZoom>
              )
            )}
          </AutoSizer>
        }
      </div>
    );
  }

}

export default BaseFloorplan;
