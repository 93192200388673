// Core
// 
import { Controller } from 'stimulus';

// Plugins
// 
import axios, { AxiosResponse } from 'axios';

// Components
// 
import Floorplan from '../../components/floorplan';

export default class extends Controller {
  static targets = ['container'];

  containerTarget: HTMLElement;

  connect() {
    this.getLegend();
  }

  async getLegend() {
    // @ts-ignore
    const response: AxiosResponse = await axios.get(this.element.dataset.url);

    this.containerTarget.innerHTML = response.data;
  }

  filter(event: any) {
    const element: HTMLElement = event.currentTarget;
    const colour: string = element.dataset.colour;

    new Floorplan().filter('colour', colour);
  }
}