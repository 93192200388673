import React from 'react';
import PubSub from 'pubsub-js';

import ActionReportFloorplan from './ActionReportFloorplan';
import ActionReportBox from './ActionReportBox';

import { addClass, removeClass, hasClass } from '../dom-helper';

const TOPIC = 'action_report.selector';

class ActionReport extends React.Component {

   constructor(props) {
    super(props);
    
    this.state = {
      activeType: null,
      items: {},
      hiddenItems: this.props.hiddenItems || [],
      deletedItems: []
    };

    this.props.types.map(function(type, index) {
      let actionedItems = [];
      if (this.props.actionedItems && this.props.actionedItems[type]) {
        actionedItems = this.props.actionedItems[type];
      }
      this.state.items[type] = actionedItems;
      
    }.bind(this));

    this.TOKEN = null;
  }

  // Subscribe to the PubSub that handles floorplan items being clicked
  componentDidMount(){
    this.TOKEN = PubSub.subscribe(TOPIC, (msg, data) => {
      if (this.state.activeType) {

        if (data.selected) {
          let item = data.item;
          let items = this.state.items;
          let hiddenItems = this.state.hiddenItems;
          let deletedItems = this.state.deletedItems;

          // Add the item to the 2 lists
          // items[this.state.activeType] = items[this.state.activeType].unshift(data.uuid);
          // hiddenItems = hiddenItems.unshift(data.uuid);
          items[this.state.activeType].unshift(item);
          hiddenItems.unshift(item.uuid);

          // Remove the item from the deleteItems if it exists
          // This has been commented out until agreed that the data should
          // remain from an edited action report item
          // deletedItems = deletedItems.filter((deletedItem) => {
          //   return deletedItem.uuid != item.uuid;
          // });

          this.setState({ items: items, hiddenItems: hiddenItems, deletedItems: deletedItems });

        } else {

          this.handleItemRemove(this.state.activeType, data.uuid);
        }
      }
    });
  }

  componentWillUnMount() {
    PubSub.unsubscribe(token);
  }

  // Handle Type/Box Selection
  handleSelection(type, selected) {
    if (selected) {
      this.setState({ activeType: type });
    } else {
      this.setState({ activeType: null });
    }
  }

  // Callback function for Box Components to call when an item is removed
  handleItemRemove(actionType, itemRemove) {
    let uuidRemove = itemRemove.uuid;
    
    // Remove the item from the items object (these are categorised by actionType)
    let items = this.state.items;

    items[actionType] = items[actionType].filter((item) => {
      return item.uuid != uuidRemove;
    });

    // Remove the item from the hiddenItems array (this is used to hide them in the Floorplan)
    // This is a little messy, having 2 item stores
    // Need to think of a better way to do this
    let hiddenItems = this.state.hiddenItems;

    hiddenItems = hiddenItems.filter((uuid) => {
      return uuid != uuidRemove;
    });


    // Add the item to the deleteItems array if action_report_item_id is present
    // This array is used for creating _destroy input fields
    let deletedItems = this.state.deletedItems;

    if (itemRemove.action_report_item_id) {
      deletedItems.push(itemRemove);
    }

    // Update the state with the new item lists
    this.setState({ items: items, hiddenItems: hiddenItems, deletedItems: deletedItems });
  }

  render() {
    let containerClass = 'cell-sm-' + (12 / this.props.types.length);

    return (

      <div className="action-report row">
        <div className="cell-sm -margin-right">

          <ActionReportFloorplan width='950' height='850' {...this.props} hiddenItems={this.state.hiddenItems} activeType={this.state.activeType} />
        </div>

        <div className="cell-sm-3">
          <div className="column">
            <div className="action-report-selector">
              {this.props.types.map(function(action_type, index) {
                return <ActionReportBox key={index} type={action_type} containerClass={containerClass} selected={this.state.activeType == action_type} items={this.state.items[action_type]} handleSelection={this.handleSelection.bind(this)} handleItemRemove={this.handleItemRemove.bind(this)} />;
              }.bind(this))}
            </div>
          </div>
        </div>

        {this.state.deletedItems.map(function(deletedItem, index) {
          return (
            <span key={index} className="-state-hidden">
              <input type="hidden" name="action_report[action_report_items_attributes][][_destroy]" value="1" />
              <input type="hidden" name="action_report[action_report_items_attributes][][id]" value={deletedItem.action_report_item_id} />
            </span>
          );
        }.bind(this))}

      </div>

    )
  }
}

export default ActionReport;
