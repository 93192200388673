import React from 'react';
import ReactDOM from 'react-dom';
import RequestPromise from 'request-promise-native';

import { ReactSVGPanZoom } from 'react-svg-pan-zoom';
import { AutoSizer } from 'react-virtualized';

import iziToast from 'izitoast';

const TOPIC = 'action_report.selector';

class ActionReportFloorplan extends React.Component {

  constructor(props) {
    super(props);
    this.Viewer = null;
    this.centered = false;

    this.state = {
      floorplanItems: this.props.items,
      selectedItem: null,
      showPreview: false
    };
  }


  handleItemClick(event) {
    // If a action type box has been selected, publish the item data
    // for the ActionReport to pick up and send the item to the "box"
    if (this.props.activeType) {
      let itemData = event.currentTarget.dataset;

      if (this.props.hiddenItems.indexOf(itemData.uuid) == -1) {
        PubSub.publish(TOPIC, {
          item: itemData,
          selected: true
        });
      }
    }
  }

  setViewer(Viewer) {
    this.Viewer = Viewer;
    if (!this.centered) {
      this.Viewer.fitToViewer();
      this.centered = true;
    }
  }

  render() {
    let dataController = null;
    let dataAction = null;

    // This is specifically for the item preview, ideally this should have been rewritten
    // but decided to use the original popup preview code, therefore we need to only set
    // the required data attributes on the svg and circle when there is NO active action box
    // selected.
    if (!this.props.activeType) {
      dataController = 'items--preview';
      dataAction = "click->items--preview#get";
    }

    let width = (document.getElementById("right-holder").offsetWidth - 10)
    let self = this;
    return (
      <div style={{ width: '100%', height: '100%' }}>
        
        <AutoSizer>
          {(
            ({ width, height }) => width === 0 || height === 0 ? null : (
              <ReactSVGPanZoom width={ width } height={ height } x='0' y='0' ref={ Viewer => Viewer && this.setViewer(Viewer) } tool={ 'auto' }>
                <svg width={ Number(this.props.floorplanWidth) } height={ Number(this.props.floorplanHeight) } className={''}>
                  <image href={ this.props.planImage } x='0' y='0' width={ this.props.floorplanWidth } height={ this.props.floorplanHeight } />
                    <svg id='floorplanItemsSVG' width={ this.props.floorplanWidth } height={ this.props.floorplanHeight } className='with-highlight' data-controller={dataController}>
                      {
                        this.state.floorplanItems.map(function(el, i) {
                          let color = el.color;

                          if (this.props.hiddenItems.indexOf(el.uuid) > -1) {
                            color = '#CCCCCC';
                          } else {
                            return(<circle className={''} style={{ cursor: 'pointer' }} key={ i } cx={ el.x } cy={ el.y } r='6' fill={color} data-thumbnail={"el.thumbnail"} data-uuid={el.uuid} data-code={el.code} data-id={el.id} data-action={dataAction} onClick={this.handleItemClick.bind(this)} />)
                          }
                        }.bind(this))
                      }
                    </svg>
                </svg>
              </ReactSVGPanZoom>
            )
          )}
        </AutoSizer>
      </div>
    );
  }
}

export default ActionReportFloorplan;
