import React from 'react';
import PubSub from 'pubsub-js';

import ActionReportReviewItem from './ActionReportReviewItem';

import { addClass, removeClass, hasClass } from '../dom-helper';

const customStyles = {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    overflow              : 'visible',
    padding               : '0',
    maxWidth              : '90%',
    minWidth              : '400px',
    background            : '#fff',
    border                : '1px solid rgb(204, 204, 204)',
    position              : 'fixed'
};

const saveButtonStyles = {
  padding: '10px 20px',
  textTransform: 'uppercase',
  textAlign: 'center',
  background: 'none',
  border: 'none',
  outline: 'none',
  cursor: 'pointer',
  color: '#7b5bff'
};

const buttonStyles = {
  padding: '10px 20px',
  textTransform: 'uppercase',
  textAlign: 'center',
  background: 'none',
  border: 'none',
  outline: 'none',
  cursor: 'pointer',
  color: '#a9a9a9'
};

class ActionReportReview extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      selectedItems: [],
      actionReportItems: this.props.actionReportItems,
      formData: {}
    }
  }

  // Handle selecting items in the list to edit (bulk edit)
  handleItemSelection(item, selected) {
    let selectedItems = this.state.selectedItems;
    let formData = this.state.formData;

    if (selected) {
      if (selectedItems.length == 0) {
        formData = {
          user_id: item.user_id,
          new_location_id: item.new_location_id,
          description: item.description
        }
      }

      selectedItems.push(item.id);

    } else {
      selectedItems = this.state.selectedItems.filter(function(selectedItemId) {
        return item.id !== selectedItemId
      });

      if (selectedItems.length == 0) {
        formData = { }
      }
    }

    this.setState({ selectedItems: selectedItems, formData: formData })
  }

  // Save the changes to the selected items
  // Loops through the selectedItems (ids) and finds each item in the
  // actionReportItems based on the id, then updates the fields
  handleSelectionSave() {
    let _newActionReportItems = this.state.actionReportItems;

    for (let i = 0; i < this.state.selectedItems.length; i++) {
      let selectedItemId = this.state.selectedItems[i];

      let actionReportItem = _newActionReportItems.find(function (obj) {
        return obj.id == selectedItemId;
      });

      if (actionReportItem) {
        actionReportItem.user_id = this.state.formData.user_id;
        actionReportItem.new_location_id = this.state.formData.new_location_id;
        actionReportItem.description = this.state.formData.description;
      }
    }

    this.setState({ actionReportItems: _newActionReportItems, selectedItems: [], formData: {} });
  }

  // Close the panel and unselect all the items
  handleVoidSelection() {
    this.setState({ selectedItems: [], formData: {} });
  }

  // Handle the change of a field on a single item
  handleSingleChange(e, item, field) {
    let _newActionReportItems = this.state.actionReportItems;

    let actionReportItem = _newActionReportItems.find(function (obj) {
      return obj.id == item.id;
    });

    if (actionReportItem) {
      actionReportItem[field] = e.currentTarget.value;
      this.setState({ actionReportItems: _newActionReportItems });
    }
  }

  // Update the formData from the bulk change form
  handleBulkChange(e, field) {
    let formData = this.state.formData;

    formData[field] = isNaN(e.currentTarget.value) ? e.currentTarget.value : parseInt(e.currentTarget.value);

    this.setState({ formData: formData, test: Math.random() });
  }


  // Display the side panel to edit multiple items at the same time
  renderSidePanel() {
    if (this.state.selectedItems.length > 0) {

      let formData = this.state.formData;

      return (
        <div className="action-report-review-panel" style={customStyles}>
          <h3 style={{ margin: '20px 30px 0 30px' }}>
            Modify selected items
            <br />
            <small style={{color: '#8e8e8e', fontWeight: 'normal'}}>
              {this.state.selectedItems.length} item(s) selected
            </small>
          </h3>
          <div style={{ padding: '20px 30px' }}>
            <div className="row -margin-top_2">
              <div className="cell-sm-12">
                <div className="fieldset">
                  <div className="legend">Destination Location</div>

                  <select className="form-control" defaultValue={formData.new_location_id} onChange={(e) => this.handleBulkChange(e, 'new_location_id')}>
                    <option></option>
                    {this.props.locations.map(function(location, index) {
                      return (
                        <option key={index} value={location.id}>{location.name}</option>
                      )
                    }.bind(this))}
                  </select>
                </div>
              </div>
            </div>

            <div className="row -margin-top_2">
              <div className="cell-sm-12">
                <div className="fieldset">
                  <div className="legend">User</div>

                  <select className="form-control" defaultValue={formData.user_id} onChange={(e) => this.handleBulkChange(e, 'user_id')}>
                    <option></option>
                    {this.props.users.map(function(user, index) {
                      return (
                        <option key={index} value={user.id}>{user.first_name} {user.last_name}</option>
                      )
                    }.bind(this))}
                  </select>
                </div>
              </div>
            </div>

            <div className="row -margin-top_2">
              <div className="cell-sm-12">
                <div className="fieldset">
                  <div className="legend">Instructions</div>
                    
                  <textarea defaultValue={formData.description} className="form-control" onBlur={(e) => this.handleBulkChange(e, 'description')} />
                </div>
              </div>
            </div>
          </div>

          <div className="row -margin-top align-middle align-right" style={{ borderTop: '1px solid #dedede', padding: '10px' }}>
            <span style={buttonStyles} onClick={this.handleVoidSelection.bind(this)}>Cancel</span>
            <span style={saveButtonStyles} onClick={this.handleSelectionSave.bind(this)}>Update</span>
          </div>
        </div>
      );
    }
  }

  // Returns boolean of whether the given item has been selected
  itemSelected(item) {
    return this.state.selectedItems.indexOf(item.id) > -1;
  }


  // Redundent
  renderReviewItem(item, index) {
    let _itemSelected = this.itemSelected(item);
    return (
      <ActionReportReviewItem key={index} locations={this.props.locations} users={this.props.users} item={item} selected={_itemSelected} handleItemSelection={this.handleItemSelection.bind(this)} handleChange={this.handleSingleChange.bind(this)} />
    );
  }


  // Redundent
  renderReviewItems(type, index) {
    if (this.state.actionReportItems[type]) {
      return (
        <div key={index} className="-margin-bottom">
          <h2 className="-weight-regular -variation-uppercase -margin-bottom_2">{type}</h2>

          {this.state.actionReportItems[type].map(function(item, index) {
            return this.renderReviewItem(item, index)
          }.bind(this))}
        </div>
      );
    } else {
      return null;
    }
  }

  render() {
    let type = null;
    let typeTitle = false;
    return (
      <div className="">

        {this.renderSidePanel()}

        {this.state.actionReportItems.map(function(item, index) {
          let _itemSelected = this.itemSelected(item);
          if (type != item.action_type) {
            type = item.action_type;
            typeTitle = true;            
          } else {
            typeTitle = false;
          }
          return (
            <div key={index}>
              {typeTitle &&
                <h2 className="-weight-regular -variation-uppercase -margin-bottom_2">{type}</h2>
              }
              <ActionReportReviewItem locations={this.props.locations} users={this.props.users} item={item} selected={_itemSelected} handleItemSelection={this.handleItemSelection.bind(this)} handleChange={this.handleSingleChange.bind(this)} />
            </div>
          );
        }.bind(this))}
        <input type="hidden" name="from_review" value="test" />
      </div>
    );
  }

}

export default ActionReportReview;
