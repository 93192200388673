import React from 'react';
import PubSub from 'pubsub-js';

import { addClass, removeClass, hasClass } from '../dom-helper';

const TOPIC = 'action_type.selector';

class ActionReportBox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: (this.props.selected || false),
      items: this.props.items || []
    }
  }

  // componentDidMount(){
  //   this.TOKEN = PubSub.subscribe(TOPIC, (msg, data) => {
  //     if (data.selected && data.type != this.props.type) {
  //       this.setState({ selected: false })
  //     }
  //   });
  // }

  // componentWillUnMount() {
  //   PubSub.unsubscribe(token);
  // }

  handleChange(event) {
    let checked = !this.props.selected;

    this.props.handleSelection(this.props.type, checked);
    
  }

  handleItemClick(event, item) {
    this.props.handleItemRemove(this.props.type, item);
  }

  render() {
    let selectedClass = this.props.selected ? '' : '';

    this.props.selected ? 'fa-check-square' : 'fa-square';
    return (
      <div className={'cell-sm-auto action-report-box'}>
        <div className={"card -margin-bottom_x2 " + selectedClass}>
          <div className="card-header row align-middle">
            <div className="cell-sm-auto -margin-right_2 -line-reset">
              <span onClick={this.handleChange.bind(this)}>
                <span className={(this.props.selected ? '' : '-state-hidden')}>
                  <i className="material-icons clickable">check_box</i>
                </span>
                <span className={(this.props.selected ? '-state-hidden' : '')}>
                  <i className="material-icons clickable">check_box_outline_blank</i>
                </span>
              </span>
            </div>

            <div className="cell-sm">
              <h5 className="-size-big d-flex row align-middle justify-content-between">
                {this.props.type}
              </h5>
            </div>
            
            <div className="cell-sm-auto">
              <span className="-weight-regular -size-medium">
                {this.props.items.length + " Asset(s)" }
              </span>
            </div>
          </div>

          <div className="card-body -padding_reset">
            <ul className='list-group list-group-flush action-report-items' data-controller="items--preview">
              {this.props.items.map(function(item, index) {
                return (
                  <li key={index} className="list-group-item">
                    <span className="d-flex justify-content-between">
                      <span data-id={item.uuid} data-code={item.code} data-action="click->items--preview#get">
                        <span className="-state-hidden"><img src={item.thumbnail} width="20px" height="20px" className="-margin-right_3" /></span>
                        <span>{item.code}</span>
                      </span>
                      <span className={this.props.selected ? '' : '-state-hidden'} onClick={(e) => this.handleItemClick(e, item)}>
                        <span className="material-icons">cancel</span>
                      </span>
                    </span>
                    {item.action_report_item_id && 
                      <input type="hidden" name="action_report[action_report_items_attributes][][id]" value={item.action_report_item_id} />  
                    }
                    
                    <input type="hidden" name="action_report[action_report_items_attributes][][item_uuid]" value={item.uuid} />
                    <input type="hidden" name="action_report[action_report_items_attributes][][item_id]" value={item.id} />
                    <input type="hidden" name="action_report[action_report_items_attributes][][action_type]" value={this.props.type} />
                  </li>
                );
              }.bind(this))}
            </ul>
          </div>
        </div>
      </div>
    )
  }
}


export default ActionReportBox;
