import React from 'react';
import PubSub from 'pubsub-js';

import { addClass, removeClass, hasClass } from '../dom-helper';

const TOPIC = 'action_report.selector';

class ActionReportReviewItem extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      selected: this.props.selected,
      item: this.props.item
    }
  }

  componentWillReceiveProps(nextProps) {
    if(this.props != nextProps) {
      this.setState({
        selected: nextProps.selected,
        item: nextProps.item
      });
    }
  }

  handleItemSelection(e) {
    this.props.handleItemSelection(this.props.item, !this.state.selected);
    this.setState({ selected: !this.state.selected });
  }

  render() {
    let item = this.state.item;

    return (
      <div className="row pill box-shadow -color-gray -padding_2 -margin-bottom_2 align-middle">

        <div className='item-selector pretty-checkbox dark'>
          <input id={ 'select-' + item.id } type='checkbox' onChange={ this.handleItemSelection.bind(this) } checked={ this.state.selected } />
          <label htmlFor={ 'select-' + item.id } className='checkbox-mask dark'></label>
        </div>


        <div className="cell-sm-2 -margin-x_2">
          <h5 className="-weight-regular -size-medium -margin-bottom_3 -color-gray_dark">Item Code</h5>
          <h3 className="-weight-regular">{item.name}</h3>
        </div>

        <div className="cell-sm -margin-right_2">
          <h5 className="-weight-regular -size-medium -margin-bottom_3 -color-gray_dark">Action</h5>
          <h4 className="-weight-regular row">{item.action_type}</h4>
        </div>

        <div className="cell-sm -margin-right_2">
          <h5 className="-weight-regular -size-medium -margin-bottom_3 -color-gray_dark">Location</h5>
          <h4 className="-weight-regular row">{item.location.name}</h4>
        </div>

        <div className="cell-sm -margin-right_2">
          <h5 className="-weight-regular -size-medium -margin-bottom_3 -color-gray_dark">Destination Location</h5>
          <h4 className="-weight-regular row">
            <select name="action_report[action_report_items_attributes][][new_location_id]" className="form-control" value={item.new_location_id || ""} onChange={(e) => this.props.handleChange(e, item, 'new_location_id')}>
              <option></option>
              {this.props.locations.map(function(location, index) {
                return (
                  <option key={index} value={location.id}>{location.name}</option>
                );
              }.bind(this))}
            </select>
          </h4>
        </div>

        <div className="cell-sm -margin-right_2">
          <h5 className="-weight-regular -size-medium -margin-bottom_3 -color-gray_dark">User</h5>
          <h4 className="-weight-regular row">
            <select name="action_report[action_report_items_attributes][][user_id]" className="form-control" value={item.user_id || ""} onChange={(e) => this.props.handleChange(e, item, 'user_id')}>
              <option></option>
              {this.props.users.map(function(user, index) {
                return (
                  <option key={index} value={user.id}>{user.first_name} {user.last_name}</option>
                );
              }.bind(this))}
            </select>
          </h4>
        </div>

        <div className="cell-12 -margin-x_2">
          <h5 className="-weight-regular -size-medium -margin-bottom_3 -color-gray_dark">Instructions</h5>

          <h4 className="-weight-regular row">
            <textarea name='action_report[action_report_items_attributes][][description]' value={item.description || ""} className='form-control' onChange={(e) => this.props.handleChange(e, item, 'description')} />
          </h4>
          <input type="hidden" defaultValue={item.id} name="action_report[action_report_items_attributes][][id]" />
        </div>
      </div>
    );
  }

}

export default ActionReportReviewItem;