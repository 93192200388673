import React, { forwardRef } from 'react';
import { ReactSortable, Sortable } from 'react-sortablejs';
import Modal from 'react-modal';

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    overflow              : 'visible',
    padding               : '0',
    width                 : '90%'
  }
};
Modal.setAppElement('body')

const CustomComponent = forwardRef((props, ref) => {
  let length = props.children.length * (180);
  return <div style={{ height: '30px', width: length + 'px' }} ref={ref}>{props.children}</div>;
});

class ColumnOrderer extends React.Component {

  constructor(props) {
    super(props);

    this.state = { modelOpen: false, orderedList: this.props.orderedList };
  }

  componentDidUpdate(prevProps) {
    if (this.props != prevProps) {
      this.setState({ orderedList: this.props.orderedList });
    }
  }

  openModal() {
    this.setState({modelOpen: true});
  }

  closeModal() {
    this.setState({modelOpen: false});
  }

  handleSort(e) {
    this.props.onSort(this.state.orderedList);
  }

  renderOrderingModel() {
    return (
      <Modal contentLabel="Example Modal" isOpen={this.state.modelOpen} onRequestClose={this.closeModal.bind(this)} style={customStyles}>
        <div className="task-modal-header -margin-bottom">
          <h2 className="task-modal-title">Customise report columns</h2>
          <p>Drag and drop the fields to change the column order of the Excel Report produced.</p>
        </div>
        <div className="task-modal-content">
          <div style={{marginBottom: '30px'}}>
            <div className="sortable-inner-container">
              <ReactSortable
                tag={CustomComponent}
                handle='.handle'
                animation='150'
                ghostClass='ghost-class'
                direction='horizontal'
                list={this.state.orderedList}
                scroll={true}
                setList={(newState) => this.setState({ orderedList: newState })}
                onSort={this.handleSort.bind(this)}
              >
                {this.state.orderedList.map((item) => (
                  <div key={item.value} className="sortable-item">
                    <div className="sortable-item-header">
                      <span className="handle">
                        <i className="fa fa-arrows-alt -margin-right_3"></i>
                        {item.label}
                      </span>
                    </div>
                    <div className="sortable-item-cell"></div>
                    <div className="sortable-item-cell"></div>
                    <div className="sortable-item-cell"></div>
                  </div>
                ))}
              </ReactSortable>
            </div>
          </div>

          <button onClick={this.closeModal.bind(this)} className="btn" >Close</button>
        </div>
      </Modal>
    );
  }


  render() {
    return (
      <div>
        <span className='btn -variation-empty -size-medium -icon -icon-only' onClick={this.openModal.bind(this)}>
          <i className='fa fa-exchange-alt'></i>
        </span>
        {this.state.modelOpen && this.renderOrderingModel()}
      </div>
    );
  }

}

export default ColumnOrderer;

