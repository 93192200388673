import React from 'react';
import ReactDOM from 'react-dom';
import RequestPromise from 'request-promise-native';

import { ReactSVGPanZoom, setPointOnViewerCenter } from 'react-svg-pan-zoom';
import { AutoSizer } from 'react-virtualized';

import iziToast from 'izitoast';

export default class Floorplan {
  constructor() {
    this.floorplanItems = []
    this.floorplanItemsCopy = [];
    this.floorplanCurrentItem = '';
    this.floorplanWidth = 0;
    this.floorplanHeight = 0;
    this.highlight = null;

    this.newCircle = true;
    this.autoPosition = false;

    this.model = 'item'; // ['item', 'location']
    this.mode = 'read'; // ['write', 'read']
    this.type = 'location'; // ['location', 'store']
    
    this.Viewer = null;
    
    this.state = {
      value: null,
      tool: ''
    };
  }

  initialize() {
    let selectors = document.querySelectorAll('[data-floorplan]');
    
    if (selectors) {
      _.forEach(selectors, (selector) => {
        if (!selector.dataset.storeplan === true) {
          this.create(selector)
        } else { }
      })
    }
  }

  create(selector, floorplan = null, type, mode, model = 'item') {
    const attributes = selector.dataset;

    this.mode = attributes.viewMode;
    this.type = type || attributes.type;
    console.log(this.type)
    this.mode = mode || attributes.viewMode;
    this.model = attributes.model || model;

    let plan, floorplanWidth, floorplanHeight;

    if (attributes.items.length > 1) { 
      this.floorplanItems = JSON.parse(attributes.items)
    }

    if (attributes.item) {
      this.floorplanCurrentItem = JSON.parse(attributes.item)
    }
    
    this.floorplanItemsCopy = this.floorplanItems;
    this.highlight = attributes.highlight
    
    if (floorplan) {
      window.URL = window.URL || window.webkitURL;
    
      const useWindowBLOB = false && window.URL;
      let that = this;

      let reader = new FileReader();

      reader.onload = function() {
        let image = new Image();
      
        image.onload = function() {
          plan            = image.src;
          floorplanWidth  = image.width;
          floorplanHeight = image.height;

          // Free up memory
          // 
          if (useWindowBLOB) {
            window.URL.revokeObjectURL(image.src)
          }
          
          that.render(selector, plan, floorplanWidth, floorplanHeight);
        }

        image.src = (useWindowBLOB ? window.URL.createObjectURL(floorplan) : reader.result);          
      }

      reader.readAsDataURL(floorplan);
    
    } else {
      plan            = attributes.plan;
      floorplanWidth  = Number(attributes.width);
      floorplanHeight = Number(attributes.height);
      
      this.render(selector, plan, floorplanWidth, floorplanHeight)
    }
  }

  render(selector, plan, floorplanWidth, floorplanHeight) {
    if (!this.highlight) {
      if (this.floorplanCurrentItem.x && this.floorplanCurrentItem.y) {
        this.highlight = this.floorplanCurrentItem.uuid;
      }
    }

    ReactDOM.render(
      <div style={{ width: '100%', height: '100%' }}>
        <AutoSizer>
          {(
            ({ width, height }) => width === 0 || height === 0 ? null : (
              <ReactSVGPanZoom ref={ Viewer => this.setViewer(Viewer) }
                // value={ this.state.value }
                // onChangeValue={ value => this.setState({ value }) }

                width={ width } height={ height }
                x='0' y='0'
                
                onClick={ event => this.onClick(event) }
                
                tool={ 'auto' }>
                <svg width={ floorplanWidth } height={ floorplanHeight } className={ this.highlight ? 'with-highlight' : '' }>
                  <image href={ plan } x='0' y='0' width={ floorplanWidth } height={ floorplanHeight } />
                    <svg id='floorplanItemsSVG' width={ floorplanWidth } height={ floorplanHeight } data-controller='items--preview' className={ this.highlight ? 'with-highlight' : '' }>
                      {
                        this.floorplanItemsCopy.map((el, i) =>
                          {
                            if (el.asset_type != 'checkpoint') {
                              return <circle className={ this.highlight && this.highlight === el.uuid ? 'highlight' : '' } style={{ cursor: 'pointer' }} key={ i } cx={ el.x } cy={ el.y } r='10' fill={ el.color || ( this.floorplanCurrentItem.id == el.id ? '#6DA9ED' : '#454F5B') } data-action="click->items--preview#get" data-id={ el.uuid } onClick={ event => this.previewDot(event, el) } />
                            } else if (el.asset_type == 'asset') {
                              return <rect className={ this.highlight && this.highlight === el.uuid ? 'highlight' : '' } style={{ cursor: 'pointer' }} key={ i } x={ el.x } y={ el.y } width='14' height='14' fill={ el.color || ( this.floorplanCurrentItem.id == el.id ? '#6DA9ED' : '#454F5B') } data-action="click->items--preview#get" data-id={ el.uuid } onClick={ event => this.previewDot(event, el) } />
                            }
                          }
                        )
                      }
                    </svg>
                </svg>
              </ReactSVGPanZoom>
            )
          )}
        </AutoSizer>
      </div>,

      selector,
    )
  }

  setViewer(Viewer) {
    this.Viewer = Viewer;
    this.Viewer.fitToViewer();
    
    if (this.floorplanCurrentItem.x && this.floorplanCurrentItem.y) {
      this.Viewer.setPointOnViewerCenter(this.floorplanCurrentItem.x, this.floorplanCurrentItem.y, 1.5)
    }
  }

  onClick(event, element = null) {
    if (this.mode === 'write') {
      if ((this.model === 'location' && this.type === 'store') || (this.model === 'item' && this.type === 'location')) {
        const svgns = 'http://www.w3.org/2000/svg',
              svg = event.SVGViewer.ownerDocument,
              circle = svg.createElementNS(svgns, 'circle');

        circle.setAttributeNS(null, 'r', 15);
        circle.setAttributeNS(null, 'fill', '#EA5455');

        if (this.newCircle) {
          circle.setAttributeNS(null, 'cx', String(event.x.toFixed(2)));
          circle.setAttributeNS(null, 'cy', String(event.y.toFixed(2)));

          document.getElementById('floorplanItemsSVG').append(circle);
          this.newCircle = false;
        } else {
          document.querySelectorAll('#floorplanItemsSVG')[0].children[document.querySelectorAll('#floorplanItemsSVG')[0].children.length - 1].setAttributeNS(null, 'cx', String(event.x.toFixed(2)))
          document.querySelectorAll('#floorplanItemsSVG')[0].children[document.querySelectorAll('#floorplanItemsSVG')[0].children.length - 1].setAttributeNS(null, 'cy', String(event.y.toFixed(2)))
        }
      }

      if (this.type === 'location') {
        document.querySelectorAll('input[name="item[x]"')[0].value = event.x;
        document.querySelectorAll('input[name="item[y]"')[0].value = event.y;

        iziToast.success({
          title: 'Asset has been placed!',
          message: ''
        })
      } else if (this.type === 'store') {
        if (this.model === 'location') {
          document.querySelectorAll('input[name="location[x]"')[0].value = event.x;
          document.querySelectorAll('input[name="location[y]"')[0].value = event.y;
        } else if (this.model === 'item') {
          if (element) {
            document.querySelectorAll('select[name="item[location_id]"]')[0].value = element.id
          }
        }

        iziToast.success({
          title: 'Location has been placed!',
          message: ''
        })
      }
    }
  }

  previewDot(event, el) {
  }

  filter(key, value) {
    this.floorplanItemsCopy = this.floorplanItems
    
    if (key === 'all') {
      this.floorplanItemsCopy = this.floorplanItems
    } else {
      console.log(this.floorplanItemsCopy)
      this.floorplanItemsCopy = this.floorplanItemsCopy.filter((i) => i.color === value)
    }
  }
}