
// Core
// 
import { Controller } from 'stimulus';

// Helpers
// 
import { addClass, removeClass, hasClass } from '../../../dom-helper';

// Plugins
// 
import RequestPromise from 'request-promise-native';

// Components
// 
import Floorplan from '../../components/floorplan';

export default class extends Controller {
  static targets = ['location'];

  connect() { }

  // Load a list of Locations based on given client_id from a remote location
  // 
  getLocations(event) {
    const url = event.target.dataset.url.replace('client_id', event.target.value);

    this.locationTarget.options.length = 0;

    RequestPromise(`${ url }?with_children=true`).then((response) => {
      const values = JSON.parse(response),
            singleValue = false;

      addClass(this.locationTarget, 'loading');

      if (values.length == 0) {
        this.locationTarget.appendChild(this.createOption('', 'No Locations found'));
      } else {
        if (!singleValue) {
          this.locationTarget.appendChild(this.createOption(null, 'Please select a Value', null, true));
        }

        _.forEach(values, (value) => {
          this.locationTarget.appendChild(this.createOption(value.id, value.name, singleValue));
        })

        removeClass(this.locationTarget, 'loading');
        addClass(this.locationTarget, 'loaded');
      }

      setTimeout(() => {
        removeClass(this.locationTarget, 'loaded')
      }, 1000);
    }).catch((error) => { });
  }

  updateFloorplan(event) {
    const url = event.target.dataset.url.replace('location_id', event.target.value);

    RequestPromise(url).then((response) => {
      const location = JSON.parse(response),
            floorplan = location.floorplan[0];

      new Floorplan().render(document.querySelector('[data-floorplan]'), floorplan.file.url, floorplan.width, floorplan.height, location.items)
    }).catch((error) => { });
  }

  createOption(value, label, selected, disabled = false) {
    let option = document.createElement('option');

    option.value    = value;
    option.selected = selected || disabled;
    
    if (disabled) {
      option.disabled = disabled
    }

    option.appendChild(document.createTextNode(label));

    return option
  }
}