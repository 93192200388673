// Core
// 
import { Controller } from 'stimulus';

// Helpers
// 
import { addClass, removeClass, hasClass } from '../../../dom-helper';

// Plugins
// 
import axios, { AxiosResponse } from 'axios';
import * as _ from 'lodash';

export default class extends Controller {
  static targets = ['client', 'uri', 'reference', 'categories', 'types', 'items'];

  clientTarget: any;
  uriTarget: any;

  referenceTarget: any;
  categoriesTarget: any;
  typesTarget: any;
  itemsTarget: any;

  uri: string;
  clientID: string;

  connect() {

    _.templateSettings = {
      interpolate: /\{\{\=(.+?)\}\}/g,
      evaluate: /\{\{(.+?)\}\}/g
    }

    this.uri = this.uriTarget.value;
    this.clientID = this.clientTarget.value;

    this.getCategories();
  }

  initialize() {
    document.addEventListener('turbolinks:load', (event) => {
      if (this.referenceTarget && this.referenceTarget.value != '') {
        this.setItem(null, this.referenceTarget.value)
      }
    })
  }

  getCategories() {
    let single: boolean = false;

    axios.get(`${ this.uri }/categories`, { headers: { 'token' : this.getUserToken() } }).then((response: AxiosResponse<any>) => {
      const categories: any[] = response.data;

      single = (categories.length === 1);

      if (single) {
        this.updateSelection(this.categoriesTarget, categories, single);
        this.getTypes(null, categories[0].id);
      } else {
        this.updateSelection(this.categoriesTarget, response.data, single);
      }
    })
  }

  getTypes(event: any, categoryID: number) {
    const $id: string = event ? event.target.value : categoryID;
    let single: boolean = false;

    axios.get(`${ this.uri }/categories/${ $id }/types`).then((response: AxiosResponse<any>) => {
      const types: any[] = response.data;

      single = (types.length === 1);

      if (single) {
        this.updateSelection(this.typesTarget, types, single);
        this.getItems(null, types[0].id);
      } else {
        this.updateSelection(this.typesTarget, response.data, single);
      }
    })
  }

  getItems(event: any, typeID: number) {
    const $id: string = event ? event.target.value : typeID;

    axios.get(`${ this.uri }/types/${ $id }/items`).then((response: AxiosResponse<any>) => {
      const items: any[] = response.data;

      let container = document.getElementById('catalogueItemsTemplate'),
          template = _.template(container.innerHTML);

      document.getElementById('catalogueItems').innerHTML = template({
        items: items
      })
    })
  }

  setItem(event: any, itemID?: string) {
    let $id: string;
    
    if (event) {
      event.preventDefault();
      $id = event.target.value ? event.target.value : event.target.dataset.id;
    } else {
      $id = itemID
    }

    axios.get(`${ this.uri }/items/${ $id }`).then((response: AxiosResponse<any>) => {
      const item: any = response.data;

      this.referenceTarget.value = item.uuid;

      let container = document.getElementById('catalogueItemTemplate'),
      template = _.template(container.innerHTML);

      document.getElementById('catalogueItem').innerHTML = template({
        item: item
      });
    })
  }

  private updateSelection(selector, values, singleValue?) {
    selector.options.length = 0;

    addClass(selector, 'loading');

    if (values.length == 0) {
      selector.appendChild(this.createOption('', 'No data received'));
    } else {
      if (!singleValue) {
        selector.appendChild(this.createOption(null, 'Please select a Value', null, true));
      }

      values.forEach((value: any) => {
        selector.appendChild(this.createOption(value.id, value.name, singleValue));
      })

      removeClass(selector, 'loading');
      addClass(selector, 'loaded');
    }

    setTimeout(() => {
      removeClass(selector, 'loaded')
    }, 1000);
  }

  private createOption(value, label, selected?: any, disabled = false) {
    let option = document.createElement('option');

    option.value    = value;
    option.selected = selected || disabled;
    
    if (disabled) {
      option.disabled = disabled
    }

    option.appendChild(document.createTextNode(label));

    return option
  }

  getUserToken() {
    const metas = document.getElementsByTagName('meta');

    for (let i = 0; i < metas.length; i++) {
      const meta = metas[i];

      if (meta.getAttribute('name') === 'user-token') {
        return meta.getAttribute('content')
      }
    }

    return null
  }
}