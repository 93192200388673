import React from 'react';
import UserManagement from './UserManagement';

class UserManagements extends React.Component {


  constructor(props) {
    super(props);

    this.state = {
      managements: this.props.managements
    }

  }

  addNewManagment() {
    let managements = this.state.managements;

    let newManagment = {
      client_id: null,
      role_id: null,
      user_id: this.props.user_id
    }

    managements.push(newManagment);
    this.setState({ managements: managements });
  }

  removeManagement(index) {
    let managements = this.state.managements;

    let management = managements[index];

    management.destroy = true;

    managements[index] = management;

    this.setState({ managements: managements });
  }

  render() {
    return (
      <div className="row">
        <div className="cell-sm-12">
          {this.state.managements.map(function(management, index) {            
            return <UserManagement key={index} position={index} clients={this.props.clients} globalRoles={this.props.global_roles} userManagement={management} removeManagement={this.removeManagement.bind(this)} />;
          }.bind(this))}
        </div>

        <div className="cell-sm-12 -margin-top">
          <div className="row">
            <span className="btn -color-pink -size-small -icon -icon-left" onClick={this.addNewManagment.bind(this)}><i className="fa fa-plus"></i><span>Add Client Permission</span></span>
          </div>
        </div>
      </div>
    );
  }

}

export default UserManagements;