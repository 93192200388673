import React from "react"

class StatusFilterRow extends React.Component {

  constructor(props) {
    super(props);
    this.state = { visible: true };
  }

  handleChange(e) {
    
  }

  removeRow() {
    this.setState({visible: false});
  }

  render() {
    let filterRows = null;
    let toRender = null;


    if (this.state.visible) {

      let selectedLocations = [];
      let availableLocations = this.props.locations || [];

      if (this.props.filterRow.location && Array.isArray(this.props.filterRow.location)) {
        selectedLocations = this.props.filterRow.location;
      } else {
        selectedLocations = [this.props.filterRow.location];
      }

      if (availableLocations.length == 0) {
        for (let i = 0; i < selectedLocations.length; i++) {
          availableLocations.push([selectedLocations[i], selectedLocations[i]])
        }
      }


      toRender = (
        <div className="row align-middle -margin-top_6 pill -padding_2 -color-gray_lighter">
          <div className="cell-sm-12">

            <label className="-size-small -margin-bottom_4">Status</label>
            <select name={this.props.fieldName + "[][name]"} className="form-control" defaultValue={this.props.filterRow.name}>
              {this.props.statuses.map(function(option, index){
                return(<option key={index} value={option[1]}>{option[0]}</option>);
              }.bind(this))}
            </select>

            <div className="row -margin-top_3">
            <div className="cell-sm-6 -padding-left_3">
                <label className="-size-small -margin-bottom_4">After</label>
                <input className="form-control" type="date" name={this.props.fieldName + "[][gte]"} defaultValue={this.props.filterRow.gte} />
              </div>
              <div className="cell-sm-6 -padding-right_3">
                <label className="-size-small -margin-bottom_4">Before (optional)</label>
                <input className="form-control" type="date" name={this.props.fieldName + "[][lte]"} defaultValue={this.props.filterRow.lte} />
              </div>
            </div>

            <label className="-size-small -margin-bottom_4 -margin-top_3">Location</label>
            <select name={this.props.fieldName + "[][location][]"} className="form-control fancy-select" multiple={true} defaultValue={selectedLocations}>
              {availableLocations.map(function(option, index) {
                return(<option key={index} value={option[1]}>{option[0]}</option>);
              }.bind(this))}
            </select>

            <div className="row">
              <div className="cell-sm"></div>
              <div className="cell-sm-auto">
                <span className="btn -color-red -size-small -margin-right_4 -icon -icon-left" onClick={this.removeRow.bind(this)}>
                  <i className="fa fa-times-circle"></i>
                  Remove Filter
                </span>
              </div>
            </div>
          </div>
          <hr className="-margin-top_3 -margin-bottom_3" />
        </div>
      );
    }


    return toRender
  }

}

export default StatusFilterRow;