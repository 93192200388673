// Core
// 
import { Controller } from 'stimulus';

// Helpers
// 
import { addClass, removeClass, hasClass } from '../../../dom-helper';
import _templateSettings from 'lodash/templateSettings';
import _template from 'lodash/template';
import Tibby from '../../../plugins/tibby';

// Plugins
// 
import axios, { AxiosResponse } from 'axios';
import TingleModal from 'tingle.js';
import baguetteBox from 'baguettebox.js';

export default class extends Controller {
  connect() {
    console.log('preview started')
    _templateSettings.interpolate = /\{\{\=(.+?)\}\}/g;
    _templateSettings.evaluate = /\{\{(.+?)\}\}/g;
  }

  get(event: any) {
    console.log(event)
    console.log('preview initiated')

    const modal: TingleModal = new TingleModal.modal({
      onOpen: () => {
        new Tibby();

        baguetteBox.run('.lightbox', {
          buttons: false
        });
      },
      beforeClose: () => {

        return true
      },
      
      onClose: () => { 
        modal.destroy()
      }
    });
    
    let IDDD;

    try {
      if (event.srcElement.dataset.id.length > 10) {
        IDDD = event.srcElement.dataset.id
      } else {
        IDDD = event.srcElement.dataset.uuid
      }
    } catch {
      console.log(event.currentTarget.dataset.id)
      if (event.currentTarget.dataset.id.length > 10) {
        IDDD = event.currentTarget.dataset.id
      } else {
        IDDD = event.currentTarget.dataset.uuid
      }
    }
    
    axios.get(`/items/${ IDDD }`).then((response: AxiosResponse<any>) => {
      modal.setContent(response.data);
      modal.open();
    })
  }
}