import React from "react"
import PropTypes from "prop-types"

import TemplateConfiguration from './TemplateConfiguration';

class TemplateForm extends React.Component {

  constructor(props) {
    super(props);

    this.state = { 
      existingConfigurations: props.existingConfigurations
    };
  }
  
  // componentDidMount(){
  //   document.addEventListener('turbolinks:before-render', function() {
  //     console.log("SHOULD UNMOUNT")
  //     ReactDOM.unmountComponentAtNode(this)
  //   });
  // }

  renderConfiguration(config, index) {
    return (
      <TemplateConfiguration key={index} selectedConfigurationKey={config.key} value={config.value} allowedConfigurations={this.props.allowedConfigurations}></TemplateConfiguration>
    );
  }

  addConfiguration() {
    let _existingConfigurations = this.state.existingConfigurations;
    _existingConfigurations.push({ key: '', value: ''})

    this.setState({ existingConfigurations: _existingConfigurations }, function() {
      window.initChoices('.fancy-select');
    });
  }

  render() {
    return (
      <div className="template-configurations">
        <div className="row align-top -margin-bottom_2">
          <div className="cell-sm-12">

            {this.state.existingConfigurations.map(function(config, index) {
              return this.renderConfiguration(config, index);
            }.bind(this))}

          </div>
        </div>

        <div className='row'>
          <span className='btn -color-pink -size-small -icon -icon-left' onClick={ this.addConfiguration.bind(this) }>
            <i className='fa fa-puzzle-piece'></i>
            Add Configuration
          </span>
        </div>
      </div>
    )
  }
} 

export default TemplateForm;
