import React from "react";
import Modal from 'react-modal';

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    overflow              : 'visible',
    padding               : '0'
  }
};
Modal.setAppElement('body')

class AddComment extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      modalIsOpen: false
    };
    this.csrfToken = "";
  }


  componentDidMount(){
    this.csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
  }


  openModal() {
    this.setState({modalIsOpen: true});
  }

  closeModal() {
    this.setState({modalIsOpen: false});
  }

  render() {
    return(
      <div className="comment-creator">
        <span onClick={this.openModal.bind(this)} className="btn -size-normal -icon -icon-only -variation-empty -margin-left_3">
          <span className="material-icons -color-blue_darker">add_comment</span>
        </span>

        <Modal contentLabel="Example Modal" isOpen={this.state.modalIsOpen} onRequestClose={this.closeModal.bind(this)} style={customStyles}>
          <div className="task-modal-header">
            <h2 className="task-modal-title">Add a comment</h2>
          </div>

          <form className="form" style={{width: 500}} action={this.props.url} acceptCharset="UTF-8" method="post">
            <div className="task-modal-content">
              <input type="hidden" name="authenticity_token" value={this.csrfToken} />
              <input type="hidden" name="_method" value="PUT" />

              <input type="hidden" name="return_url" value={this.props.return_url}/>


              <textarea name="comment[body]" className="form-control -margin-top" rows="7" placeholder="Add a comment..."></textarea>

            </div>



            <div className="row -margin-top align-middle align-right modal-buttons">
              <button onClick={this.closeModal.bind(this)} className="modal-button modal-button-cancel" >Cancel</button>
              <input type="submit" name="commit" value="Submit" className="modal-button modal-button-save" data-disable-with="Submitting" />
            </div>
          </form>
        </Modal>
      </div>
    );
  }
}

export default AddComment;
