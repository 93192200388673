import React from 'react';
import PubSub from 'pubsub-js';

import { addClass, removeClass, hasClass } from '../dom-helper';

const TOPIC = 'reuse.item.selector';

let COUNTER = 0;

class ReuseItemSelector extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      selected: (props.selected || false)
    }
  }

  // componentDidMount(){
  //   this.TOKEN = PubSub.subscribe(ALLTOPIC, (msg, data) => {
      
  //     this.setState({ selected: data.selected }, function() {
  //       this.publishChange(data.selected);
  //     });
      
  //   });
  // }

  // componentWillUnMount() {
  //   PubSub.unsubscribe(this.TOKEN);
  // }

  publishChange(checked) {
    PubSub.publish(TOPIC, {
      uuid: this.props.uuid,
      selected: checked
    });

  }

  handleChange(event) {
    let checked = !this.state.selected;
    
    this.publishChange(checked);
    
    this.setState({ selected: checked });
  }

  render() {
    return (
      <div className='item-selector pretty-checkbox'>
        <input id={ this.props.uuid } type='checkbox' onChange={ this.handleChange.bind(this) } checked={ this.state.selected } />
        <label htmlFor={ this.props.uuid } className='checkbox-mask'></label>
      </div>
    )
  }
}

export default ReuseItemSelector;
