// Core
// 
import { Controller } from 'stimulus';

// Helpers
// 
import { addClass, removeClass, hasClass } from '../../../dom-helper';

// Plugins
// 
import TingleModal from 'tingle.js';
import axios, { AxiosResponse } from 'axios';

export default class extends Controller {
  static targets = [
    'form', 'response', 'counter', 'actions',
    'floorplanOverviewAction', 'bulkEditAction',
    'attributesContainer', 'reportsContainer', 'reportTemplatesContainer', 'itemsContainer'
  ];


  // ['form', 'counter', 'actions']
  // ['task', 'overview', 'report', 'edit']

  formTarget: any;
  responseTarget: any;
  counterTarget: any;
  actionsTarget: any;

  // floorplanOverviewActionTarget: any;
  bulkEditActionTarget: any;

  attributesContainerTarget: any;
  reportsContainerTarget: any;
  reportTemplatesContainerTarget: any;
  itemsContainerTarget: any;

  refresh(event) {
    
    // Dont submit the form if the target is a datepicker range because
    // 
    if (!hasClass(event.target, 'datepicker-range')) {
      this.formTarget.submit()
    }
  }

  overviewAssets() {
    if (hasClass(this.itemsContainerTarget, '-state-invisible')) {
      removeClass(this.itemsContainerTarget, '-state-invisible')
    } else {
      addClass(this.itemsContainerTarget, '-state-invisible')
    }
  }

  showAdditionalFilters() {
    event.preventDefault();

    const modal = new TingleModal.modal({
      footer: true,
      stickyFooter: false,
      onOpen: () => { },
      beforeClose: () => {
        return true
      },
      
      onClose: () => { 
        modal.destroy()
      }
    });

    modal.setContent(this.attributesContainerTarget.innerHTML);
    modal.open();
  }

  showReportingOptions(event: any) {
    event.preventDefault();

    const modal = new TingleModal.modal({
      onOpen: () => { },
      beforeClose: () => {
        return true
      },
      
      onClose: () => { 
        modal.destroy()
      }
    });

    modal.setContent(this.reportsContainerTarget.innerHTML);
    modal.open();
  }

  showReportTemplates(event: any) {
    event.preventDefault();

    const modal = new TingleModal.modal({
      onOpen: () => { },
      beforeClose: () => {
        return true
      },
      
      onClose: () => { 
        modal.destroy()
      }
    });
    modal.setContent(this.reportTemplatesContainerTarget.innerHTML);
    modal.open();
  }

  bulkEdit() { }

  submitReportRequest() {

  }

  initialize() {
    // addClass(this.floorplanOverviewActionTarget, 'disabled');
    // addClass(this.bulkEditActionTarget, 'disabled');
  }

  connect() { 
    console.log('connected?')
  }

  disconnect() { }
}