function titleize(str) {
  let _str = String(str).replace('.name', '');
  return _.capitalize(_str.replace(/\_/g, ' '));
}


import React from "react"
import PropTypes from "prop-types"
import Select from "react-select"
import ColumnOrderer from './ColumnOrderer';

class TemplateConfiguration extends React.Component {

  constructor(props) {
    super(props);

    let value = props.value;

    if (Array.isArray(props.value)) {
      value = props.value.map(function(v) { return { value: v, label: titleize(v) } });
    }

    this.state = { 
      selectedConfigurationKey: props.selectedConfigurationKey,
      selectedConfigurationValue: value,
      selectedConfiguration: this.getSelectedConfiguration(props.selectedConfigurationKey)
    };
  }

  // Get the selected configuration object with the given selected key
  getSelectedConfiguration(selectedKey) {
    let selectedConfiguration = {};
    
    // Loop through the allowed configurations and find the one that is selected
    if (selectedKey != null || selectedKey != undefined) {
      for (let i = 0; i < this.props.allowedConfigurations.length; i++){
        let config = this.props.allowedConfigurations[i];
        
        if (config.name == selectedKey) {
          selectedConfiguration = config;
          break;
        }
      }
    }

    return selectedConfiguration;
  }


  // Handle the configuration type change and update the state
  // with the new configuration selection and reset the value
  handleFieldChange(e) {
    // If we get issues with 'value' look at using e.target.selectedOptions
    let selectedKey = e.target.value;
    
    let selectedConfiguration = this.getSelectedConfiguration(selectedKey);

    if (selectedConfiguration) {
      this.setState({ 
        selectedConfiguration: selectedConfiguration, 
        selectedConfigurationKey: selectedConfiguration.name,
        selectedConfigurationValue: null 
      }, function () {
        window.initChoices('.fancy-select');
      });
    }

  }

  // Handle the configuration value change and update the state
  handleValueChange(e) {
    let value = e.target.value;
    
    if (e.target.selectedOptions != undefined) {
      value = [];

      for (let index = 0; index < e.target.selectedOptions.length; index++) {
        let option = e.target.selectedOptions[index];

        if (option.value) {
          value.push(option.value);
        }
      }
    }

    this.setState({ selectedConfigurationValue: value });
  }

  handleSelectChange(v) {
    this.setState({ selectedConfigurationValue: v });
  }

  handleColumnOrderChange(newOrderedValue) {

    this.setState({ selectedConfigurationValue: newOrderedValue });
  }

  // Render an input field for the configuration value
  // Input type is determined by the type of the configuration selected
  renderConfigurationValueField() {
    let valueField = null;
    let defaultValue = null; 
    let fieldName = "template[configuration][][value]";

    defaultValue = this.state.selectedConfigurationValue;

    if (this.state.selectedConfiguration) {
      switch(this.state.selectedConfiguration.type) {
        case 'multiple_select':
        case 'select':
          valueField = this.buildSelectField(defaultValue, fieldName);
          break;
        case 'boolean':
          valueField = this.buildCheckboxField(defaultValue, fieldName);
          break;
        default:
          valueField = this.buildInputField(defaultValue, fieldName);
      }
    } else {
      console.log("NO SELECTED CONFIG")
    }

    return valueField;
  }

  // Return a Checkbox input
  buildCheckboxField(defaultValue, fieldName) {
    let checked = false;

    if (defaultValue && !(defaultValue instanceof Array)){
      checked = true;
    }

    return (
      <input name={fieldName} type="checkbox" defaultChecked={checked} value="1" onChange={this.handleValueChange.bind(this)} />
    );
  }

  // Return a generic input field (text/number)
  buildInputField(defaultValue, fieldName) {
    let fieldType = 'text';
    if (this.state.selectedConfiguration && this.state.selectedConfiguration.type) {
      fieldType = this.state.selectedConfiguration.type;
    }
    return (
      <input name={fieldName} type={fieldType} defaultValue={defaultValue} onChange={this.handleValueChange.bind(this)} className="form-control" />
    );
  }

  // Return a Select field
  buildSelectField(defaultValue, fieldName) {
    let multiple = false;

    if (this.state.selectedConfiguration.type == 'multiple_select') {
      multiple = true;
      fieldName = fieldName + '[]';
      if (!(defaultValue instanceof Array)) {
        defaultValue = [defaultValue];
      }
    }

    // return (
    //   <select name={fieldName} multiple={multiple} defaultValue={defaultValue} onChange={this.handleValueChange.bind(this)} className="form-control fancy-select">
    //     {this.state.selectedConfiguration.values.map(function(value, index){
    //       let option = { name: value };
    //       return this.renderOption(option, index);
    //     }.bind(this))}
    //   </select>
    // );
    
    return (
      <div className="row">
        <div className="cell-sm">
          <Select name={fieldName} value={defaultValue} onChange={this.handleSelectChange.bind(this)} isMulti={multiple} options={this.groupedOptions()} />
        </div>
        {this.state.selectedConfiguration.name == 'display_attributes' &&
          <div className="cell-sm-auto align-middle">
            <ColumnOrderer orderedList={defaultValue} onSort={this.handleColumnOrderChange.bind(this)} />
          </div>
        }
      </div>
    );
  }

  groupedOptions() {
    //this.state.selectedConfiguration.values.map(function(option) { return { value: option, label: titleize(option) } } )
    if (this.state.selectedConfiguration.name == 'display_attributes') {
      return this.state.selectedConfiguration.values.map(function(option) { 
        console.log(option[1].map(function(opt) { return { value: opt, label: titleize(opt) } } ));
        return { label: option[0], options: option[1].map(function(opt) { return { value: opt, label: titleize(opt) } } ) } 
      });
    } else {
      return this.state.selectedConfiguration.values.map(function(option) { return { value: option, label: titleize(option) } } )
    }
  }

  // Return an Option element for a Select field
  renderOption(option, index) {
    return (
      <option key={index} value={option.name}>{titleize(option.name)}</option>
    );
  }

  removeConfiguration() {
    this.setState({ deleted: true });
  }

  render() {
    let rowContent = null;

    if (!this.state.deleted) {
      rowContent = (
        <div className="row align-middle pill -padding_2 -margin-top_6">
          <div className="cell-sm align-top--self">
            <select name="template[configuration][][key]" onChange={this.handleFieldChange.bind(this)} defaultValue={this.state.selectedConfigurationKey} className="form-control">
              <option></option>
              {this.props.allowedConfigurations.map(function(option, index) {
                return this.renderOption(option, index);
              }.bind(this))}
            </select>
          </div>
          
          <div className="cell-sm-5 -margin-x_2">
            {this.renderConfigurationValueField()}
          </div>

          <div className="cell-sm-auto align-top">
            <span className="btn -variation-empty -size-medium -color-red -icon -icon-only" onClick={ this.removeConfiguration.bind(this) }>
              <i className="fa fa-trash-alt"></i>
            </span>
          </div>
        </div>
      )
    }

    return rowContent;
  }
}

TemplateConfiguration.defaultProps = {
  deleted: false,
  selectedConfigurationKey: null,
  selectedConfigurationValue: null,
  selectedConfiguration: {}
};

export default TemplateConfiguration;