/* eslint no-console:0 */

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//

import Rails from 'rails-ujs';
import Turbolinks from 'turbolinks';
// import JQuery from 'jquery';
// import select2 from 'select2';

import { Application } from 'stimulus';
import { definitionsFromContext } from 'stimulus/webpack-helpers';

import { addClass, removeClass, hasClass } from '../dom-helper';
import { on, off, fire } from '../delegated-events';

import React from 'react';
import ReactDOM from 'react-dom';

import * as _ from 'lodash';

import Choices from 'choices.js';
import SweetAlert from 'sweetalert';
import axios from 'axios';
import tippy from 'tippy.js';
import flatpickr from 'flatpickr';
import baguetteBox from 'baguettebox.js';

import Floorplan from './components/floorplan';
// import TemplateForm from './components/template_form';
import Tibby from '../plugins/tibby';

import ItemsIndex from './items/index';
import ItemsForm from './items/form';
import ItemsShow from './items/show';

// FontAwesome
// 
import FontAwesome from '@fortawesome/fontawesome';
import solid, { faWindowMinimize } from '@fortawesome/fontawesome-free-solid';
import regular from '@fortawesome/fontawesome-free-regular';
import brands from '@fortawesome/fontawesome-free-brands';

Rails.start();
Turbolinks.start();

const application = Application.start(),
      context = require.context("./controllers", true, /\.(js|ts|tsx|jsx)$/);

application.load(definitionsFromContext(context));

window.initDefaultChoices = function(selector) {
  new Choices(selector, {
    placeholder: true,
    placeholderValue: 'Additional options..',
    removeItems: true,
    removeItemButton: true,
    itemSelectText: ''
  })
}

window.initChoices = function(selector) {
  let choices = new Choices(selector, {
    placeholder: true,
    placeholderValue: 'Select choices..',
    removeItems: true,
    removeItemButton: true,
    itemSelectText: '',
    callbackOnCreateTemplates: function(template) {
      let classNames = this.config.classNames;
      let itemSelectText = this.config.itemSelectText;

      return {
        item: function(data) {
          let dataset = data.label.split('~'),
              label   = dataset[0],
              count   = dataset[1] || '-';
            
          const classes = [
            'pill -color-blue',
            String(classNames.item),
            String(data.highlighted ? classNames.highlightedState : classNames.itemSelectable)
          ]

          return template(`
            <div class='${ classes.join(' ') }'
              data-item data-id='${ String(data.id) }' data-value='${ String(data.value) }'

              ${ String(data.active ? 'aria-selected="true"' : '') }
              ${ String(data.disabled ? 'aria-selected="true"' : '') }
            >
              
              <span class='-color-white -line-reset'>${ String(label) }</span>
              <button type="button" class="choices__button" data-button="">
                X
              </button>
            </div>
          `);
        },

        choice: function(data) {
          let dataset = data.label.split('~'),
              label   = dataset[0],
              count   = dataset[1] || '-';

          const classes = [
            'row align-middle -padding_2',
            String(classNames.item),
            String(classNames.itemChoice),
            String(data.disabled ? classNames.itemDisabled : classNames.itemSelectable)
          ]

          let counter = null;

          if (count && count != '-') {
            counter = `<div class='cell-sm-auto pill -color-blue -padding_3'>
              <p class='-color-white -weight-regular -size-small -line-reset'>
                ${ String(count) }
              </p>
            </div>`
          } else { 
            counter = ''; 
          }

          return template(`
            <div class='${ classes.join(' ') }'
              data-choice='' data-id='${ String(data.id) }' data-select-text='${ String(itemSelectText) }' data-value='${ String(data.value) }'

              ${ String(data.disabled ? 'data-choice-disabled aria-disabled="true"' : 'data-choice-selectable') }
              ${ String(data.groupId > 0 ? 'role="treeitem"' : 'role="option"') }
            >
              
              <div class='cell-sm ${ counter ? '-margin-right_3' : '' }'>
                <p class='-line-reset_2'>
                  ${ String(label) }
                </p>
              </div>
              
              ${ counter }
            </div>
          `);
        },
      };
    }
  });
}

export default class AwesomeAssets {
  constructor(event) {
    this.event = event
  }

  initialize() {
    const body = document.getElementsByTagName('body')[0];

    document.querySelectorAll('.onChangeSubmit').forEach((form) => {
      form.addEventListener('change', () => {
        form.submit()
      })
    });
    
    document.querySelectorAll('.selectAllOnChange').forEach((selector) => {
      selector.addEventListener('change', (selec) => {
        const checked = selec.target.checked;
        
        document.querySelectorAll(`.${ selec.target.name }`).forEach((s) => {
          s.checked = checked;
          
          if (s.options && s.options.length > 0) {
            for (var i = 0; i < s.options.length; i++) {
              s.options[i].selected = checked;
            }  
          }
        });
      });
    });

    new Floorplan().initialize();

    axios.defaults.headers.common['Accept'] = 'text/javascript';
    axios.defaults.headers.common['Content-Type'] = 'text/javascript';
    axios.defaults.headers.common['X-CSRF-Token'] = this.getCSRFToken();
    axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
    axios.defaults.headers.common['token'] = this.getUserToken();

    baguetteBox.run('.lightbox', {
      buttons: false
    });

    new Tibby();

    const filterable = document.querySelector('input.filterable');

    if (filterable) {
      filterable.addEventListener('input', function(event) {
        const selectors = document.querySelectorAll('.filterable-child');
        const query = event.target.value;

        const matches = Array
          .from(selectors)
          .filter((selector) => selector.dataset.name.match(`[^,]*${ query.toLowerCase() }[,$]*`) || selector.dataset.address.match(`[^,]*${ query.toLowerCase() }[,$]*`));

        selectors.forEach((selector) => {
          if (query.trim !== '') {
            if (matches.includes(selector)) {
              selector.style.display = 'flex';
            } else {
              selector.style.display = 'none';
            }
          }
        })
      });
    }

    tippy('[data-tippy]', {
      dynamicTitle: true,
      animation: 'scale',
      size: 'small',
      duration: 0,
      arrow: true
    });

    (function() {
      var $body = document.body,
          $menu_trigger = $body.getElementsByClassName('menu-trigger')[0];
    
      if ( typeof $menu_trigger !== 'undefined' ) {
        $menu_trigger.addEventListener('click', function() {
          $body.className = ( $body.className == 'menu-active' )? '' : 'menu-active';
        });
      }
    }).call(this);

    document.querySelectorAll('.toggle').forEach((element, index) => {
      element.addEventListener('click', (event) => {
        const child = document.getElementById(event.srcElement.dataset.target);

        if (child.style.display == 'none') {
          child.style.display = 'block'
        } else {
          child.style.display = 'none'
        }
      }, true);
    });

    document.querySelectorAll('.datetime').forEach((selector) => {
      flatpickr(selector, {
        dateFormat: 'd/m/Y'
      });
    })

    document.querySelectorAll('.datepicker').forEach((selector) => {
      const options = {};
            options['dateFormat'] = 'd/m/Y';

      if (hasClass(selector, 'datepicker-range')) {
        options['mode'] = 'range'
      }

      if (!hasClass(selector, 'no-submit')) {
        options['onChange'] = (selectedDates, dateStr, instance) => {
          if (selectedDates.length === 2) {
            instance.input.value = dateStr;
            instance.input.form.submit();
          }
        }
      }

      flatpickr(selector, options);
    })
    
    // Initialize Plugins
    //
    // TODO: Depending on its usage, this might need to be moved into ItemIndex
    //       since way this is created is mainly for the Filters which have
    //       counters that alse need shown.
    //       The same might not apply to other elements
    //
   

    if (document.querySelectorAll('[data-choice]').length != 0) {
      window.initChoices('[data-choice]');
    }

    if (document.querySelectorAll('[data-default-choice]').length != 0) {
      window.initDefaultChoices('[data-default-choice]');
    }

    // Initialize modules
    // 
    if (hasClass(body, 'items-page_new') || hasClass(body, 'items-page_edit')) {
      new ItemsForm().initialize();
    }

    if (hasClass(body, 'items-page_show')) {
      new ItemsShow().initialize();
    }
  }

  getCSRFToken() {
    const metas = document.getElementsByTagName('meta');

    for (let i = 0; i < metas.length; i++) {
      const meta = metas[i];

      if (meta.getAttribute('name') === 'csrf-token') {
        return meta.getAttribute('content')
      }
    }

    return null
  }

  getUserToken() {
    const metas = document.getElementsByTagName('meta');

    for (let i = 0; i < metas.length; i++) {
      const meta = metas[i];

      if (meta.getAttribute('name') === 'user-token') {
        return meta.getAttribute('content')
      }
    }

    return null
  }

}

var componentRequireContext = require.context("components", true)
var ReactRailsUJS = require("react_ujs")
ReactRailsUJS.useContext(componentRequireContext)

document.addEventListener('turbolinks:load', (event) => {
  new AwesomeAssets(event).initialize()
  ReactRailsUJS.mountComponents();
});

document.addEventListener('turbolinks:before-render', (event) => {
  FontAwesome.dom.i2svg({
    node: event.data.newBody
  });
  
  ReactRailsUJS.unmountComponents();
});