// Core
// 
import { Controller } from 'stimulus';

// Helpers
// 
import { addClass, removeClass, hasClass } from '../../../dom-helper';

// Plugins
// 
import TingleModal from 'tingle.js';
import axios, { AxiosResponse } from 'axios';

export default class extends Controller {
  static targets = ['reportOptionsAction', 'reportTemplatesContainer'];

  reportOptionsActionTarget: any;
  reportTemplatesContainerTarget: any;

  initialize() {

  }

  connect() {
  }


  showReportTemplates(event: any) {
    event.preventDefault();

    const modal = new TingleModal.modal({
      onOpen: () => { },
      beforeClose: () => {
        return true
      },
      
      onClose: () => { 
        modal.destroy()
      }
    });

    modal.setContent(this.reportTemplatesContainerTarget.innerHTML);
    modal.open();
  }
  disconnect() { }
}