// Core
// 
import { Controller } from 'stimulus';

// Plugins
// 
import TingleModal from 'tingle.js';

export default class extends Controller {
  static targets = ['stampsContainer'];

  stampsContainerTarget: any;

  connect() { }

  view(event: any) {
    event.preventDefault();

    const modal = new TingleModal.modal({
      onOpen: () => { },
      beforeClose: () => {
        return true
      },
      
      onClose: () => { 
        modal.destroy()
      }
    });

    modal.setContent(this.stampsContainerTarget.innerHTML);
    modal.open();
  }

  disconnect() { }
}