import * as $ from '../../dom-helper';

import RequestPromise from 'request-promise-native';
import TingleModal from 'tingle.js';

export default class ItemShow {
  constructor() { }

  initialize() {
    
    // Because we're using data-action as our selectors, we should move this
    // into the application initializer since it doesn't necesarilly applies
    // just to this function anymore
    //
    let selectors = document.querySelectorAll('[data-action]');
    
    document.addEventListener('click', (event) => {
      selectors.forEach((element, index) => {
  
        // console.log(element)
        
        // element.addEventListener('click', (event) => {
        //   console.log('a')
        //   console.log(event)
        //   console.log('b')
        // })

        if (event.target == element) {
          event.stopPropagation();
          event.preventDefault();          

          let attributes = element.dataset;

          if (attributes.action == 'addNote()') {
            this.addNote(attributes.url)
          }

          if (attributes.action == 'closeModal()') {
            this.closeModal()
          }

        }
      });
    });
  }

  addNote(uri) {
    let uriOptions = {
      method: 'GET',
      uri: uri,
      responseType: 'json',
      headers: {
        'Accept': 'text/javascript',
        'Content-Type': 'text/javascript',
        'X-CSRF-Token': this.getCSRFToken(),
        'X-Requested-With': 'XMLHttpRequest'
      }
    }

    RequestPromise(uriOptions).then((response) => {
      this.modal(response, uri)
    }).catch((error) => {
      console.error(error)
    });
  }


  modal(content, uri, modalType = 'page') {
    this.modalWindow = new TingleModal.modal({
      footer: false,
      stickyFooter: false,
      closeMethods: ['overlay', 'button', 'escape'],
      closeLabel: 'Close',
      cssClass: [modalType],
      onOpen: () => { },
      beforeClose: () => {
        return true;
        return false;
      },
      onClose: () => {
        this.closeModal()
      }
    });

    this.modalWindow.setContent(content);
    this.modalWindow.open();
  }

  closeModal() {
    this.modalWindow.destroy()
  }

  getCSRFToken() {
    const metas = document.getElementsByTagName('meta');

    for (let i = 0; i < metas.length; i++) {
      const meta = metas[i];

      if (meta.getAttribute('name') === 'csrf-token') {
        return meta.getAttribute('content');
      }
    }

    return null
  }
}