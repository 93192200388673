import React from 'react';

class Search extends React.Component {

  constructor(props) {
    super(props);
    this.state = { value: props.value, focus: false };
    // this.componentCache = this.componentCache.bind(this);
  }

  // componentCache() {
  //   $(ReactDOM.findDOMNode(this)).find("input").val('');
  // }

  // componentDidMount() {
  //   $(document).on("turbolinks:before-cache", this.componentCache);
  // }

  // componentWillUnmount() {
  //   $(document).off("turbolinks:before-cache", this.componentCache);
  // }

  handleKeyUp(e) {
    this.setState({value: e.target.value});

    if (e.key === 'Enter') {
      this.handleSearch();
    }
  }

  handleSearch() {
    var url = this.props.url;
    var urlJoin = url.indexOf('?') != -1 ? '&' : '?';
    if (this.state.value && this.state.value.length > 0) {
      url = url + urlJoin + "q=" + this.state.value;
    }
    Turbolinks.visit(url);
  }

  handleFocus() {
    this.setState({ focus: !this.state.focus });
  }

  handleClear() {
    Turbolinks.visit(this.props.url);
  }

  render() {
    var className = "row input-group search-group " + this.props.cssClass;

    var clearSearch;
    if (this.state.value && this.state.value.length > 0) {
      clearSearch = <i onClick={this.handleClear.bind(this)} className="material-icons absolutely pos-y-middle pos-right -margin-right_2" aria-hidden="true">highlight_off</i>;
    }

    var placeholder = this.props.placeholder || 'Search...';
    if (this.state.focus) {
      className = className + " search-group-focus";
      placeholder = '';
    }

    return (
      <div className={className}>
        <div className="cell-sm relatively">
          <input type="text" defaultValue={this.props.value} className="form-control" placeholder={placeholder} onKeyUp={this.handleKeyUp.bind(this)} onFocus={this.handleFocus.bind(this)} onBlur={this.handleFocus.bind(this)} />
          {clearSearch}
        </div>
        <div className="cell-sm-auto">
          <button onClick={this.handleSearch.bind(this)} className="btn" style={{ borderRadius: 0, padding: '13px' }} type="button"><i className="material-icons" aria-hidden="true">search</i></button>
        </div>
      </div>
    )
  }

}

export default Search;