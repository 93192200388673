import React from 'react';
import ReactDOM from 'react-dom';
import RequestPromise from 'request-promise-native';
import Modal from 'react-modal';
import PubSub from 'pubsub-js';

import { ReactSVGPanZoom } from 'react-svg-pan-zoom';
import { AutoSizer } from 'react-virtualized';

import iziToast from 'izitoast';

import BaseFloorplan from './BaseFloorplan';
import StoreMetrics from './StoreMetrics';

const TOPIC = 'store_levels';

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    overflow              : 'visible',
    padding               : '0',
    width                 : '950px'
  }
};
Modal.setAppElement('body')

class StoreFloorplan extends React.Component {

  constructor(props) {
    super(props);
    this.Viewer = null;
    this.centered = true;

    let stacked_level = this.props.stacked_level || 0;
    let selectedItem = {};

    // Set the selectedItem based on the given selectedItemId
    // Need to do this as JS is pass by value so the selectedItem
    // needs to actually BE THE object from the array -- opposed to passing it in from Rails
    if (this.props.selectedItemId) {
      selectedItem = this.props.items.find(function(item){
        return item.id == this.props.selectedItemId;
      }.bind(this));
    }

    
    let editMode = !!this.props.selectedItemId;
    let visibleFloorplanItems;

    visibleFloorplanItems = this.props.items.filter((item) => {
      return item.stacked_level == stacked_level;
    });

    this.state = {
      floorplanItems: this.props.items,
      visibleFloorplanItems: visibleFloorplanItems,
      currentLevelItems: visibleFloorplanItems,
      itemsToRemove: [],
      selectedItem: selectedItem,
      stacked_level: stacked_level,
      addMode: false,
      modalIsOpen: false,
      levels: this.props.levels,
      editMode: editMode,
      heatMap: false,
      hideFloorplan: !!this.props.hideFloorplan
    };
  }

  componentDidMount(){
    this.TOKEN = PubSub.subscribe(TOPIC, (msg, data) => {
      this.setState({ levels: data.levels });
    });
  }

  componentWillUnMount() {
    PubSub.unsubscribe(this.TOKEN);
  }

  openModal(item) {
    this.setState({modalIsOpen: true, selectedItem: item});
  }

  closeModal() {
    this.setState({modalIsOpen: false, selectedItem: {}});
  }

  updateItem(formItem) {
    let floorplanItems = this.state.floorplanItems;

    for (let i = 0; i < floorplanItems.length; i++) {
      let item = floorplanItems[i];
      if (formItem.id == item.id) {

        floorplanItems[i] = formItem;

        this.setState({ floorplanItems: floorplanItems });

        break;
      }
    }

  }

  removeItem(toRemove) {
    let newItems = this.state.floorplanItems.filter(function(item){
      return item.id != toRemove.id;
    });

    this.state.itemsToRemove.push(toRemove);

    this.state.floorplanItems = newItems;
    this.setLevel(this.state.stacked_level);
  }

  handleItemClick(event, item) {
    if (!this.state.addMode && this.state.selectedItem.id != item.id){// && !element.newRecord) {
      //window.location.href = this.assetsUrl(element);
      this.openModal(item);
    }
  }

  setSelectedItem(item) {
    let newState = { addMode: false, selectedItem: item };

    if (item) {
      newState.editMode = true;
    }

    this.setState(newState);
  }

  handleFloorplanClick(event) {
    if (this.state.addMode) {

      let items = this.state.floorplanItems;
      let levelItems = this.state.visibleFloorplanItems;

      let item = { id: (+ new Date()), name: '', color: '#cccccc', x: String(event.x.toFixed(2)), y: String(event.y.toFixed(2)), stacked_level: this.state.stacked_level, newRecord: true }

      items.push(item);
      levelItems.push(item);

      this.setState({ floorplanItems: items, visibleFloorplanItems: levelItems });
    } else if (this.state.selectedItem && this.state.editMode) {
      let item = this.state.selectedItem;

      item.x = String(event.x.toFixed(2));
      item.y = String(event.y.toFixed(2));

      this.setState({ selectedItem: item });
    }
  }

  renderSelectedItemNotice() {
    if (this.props.selectedItemId) {
      return (
        <div className="row -margin-bottom_2 -padding_2 pill -color-gray_dark">
          <div className="cell-sm"><h3 className="-margin-right_3 -line-reset -weight-medium -margin-top_4  -style-four -smaller"><span style={{color:"#ffffff"}}>Click on the floorplan where you wish to place the Storage container.</span></h3></div>
        </div>
      )
    }
  }

  /* ADD MODE TOGGLE */
  renderAddToggle() {

    if (this.props.writeMode && !this.props.selectedItemId) {
      let onColour = '-color-dark-grey';
      let offColour = '-color-red_dark';
      if (this.state.addMode == true) {
        onColour = '-color-blue_dark';
        offColour = '-color-dark-grey';
      }
      return (
        <div className="row -margin-bottom_2 -padding_2 pill -color-gray_dark">
          <div className="cell-sm"><h3 className="-margin-right_3 -line-reset -weight-medium -margin-top_4  -style-four -smaller"><span style={{color:"#ffffff"}}>Click floorplan to add Sub Locations:</span></h3></div>
          <div className="cell-sm-auto"><span className={"clickable -margin-right_3 -line-reset -weight-thin npill -style-four -smaller " + onColour} onClick={() => this.addToggle(true)}>On</span></div>
          <div className="cell-sm-auto"><span className={"clickable -margin-right_3 -line-reset -weight-thin npill -style-four -smaller " + offColour} onClick={() => this.addToggle(false)}>Off</span></div>
        </div>
      );
    } else {
      return null;
    }
  }

  addToggle(addState) {
    this.setState({ addMode: addState, editMode: false, selectedItem: {} });
  }

  renderHeatMapToggle() {
    if (!this.props.writeMode) {
      let onColour = '-color-dark-grey';
      let offColour = '-color-red_dark';
      if (this.state.heatMap == true) {
        onColour = '-color-blue_dark';
        offColour = '-color-dark-grey';
      }
      return (
        <div className="row -margin-bottom_2 -padding_2 pill -color-gray_dark">
          <div className="cell-sm"><h3 className="-margin-right_3 -line-reset -weight-medium -margin-top_4  -style-four -smaller"><span style={{color:"#ffffff"}}>Heat Map:</span></h3></div>
          <div className="cell-sm-auto"><span className={"clickable -margin-right_3 -line-reset -weight-thin npill -style-four -smaller " + onColour} onClick={() => this.heatMapToggle(true)}>On</span></div>
          <div className="cell-sm-auto"><span className={"clickable -margin-right_3 -line-reset -weight-thin npill -style-four -smaller " + offColour} onClick={() => this.heatMapToggle(false)}>Off</span></div>
        </div>
      );
    } else {
      return null;
    }
  }

  heatMapToggle(heatMapState) {
    this.setState({ heatMap: heatMapState });
  }

  heatMapColour(item) {
    let colour = item.color;

    if (item.cubic_usage_percentage < 50) {
      colour = '#82a877';
    } else if (item.cubic_usage_percentage < 90) {
      colour = '#ef9a54';
    } else if (item.cubic_usage_percentage < 98) {
      colour = '#ec5b52';
    } else {
      colour = '#c54951';
    }

    return colour;
  }

  renderCapacityIndicator(percentage) {
    //let percentage = item.cubic_usage_percentage//((used || 1) / (total || 1)) * 100
    
    let colour = '#cccccc';

    if (percentage < 50) {
      colour = 'green';
    } else if (percentage < 90) {
      colour = 'amber';
    } else if (percentage < 98) {
      colour = 'red';
    } else {
      colour = 'dark-red';
    }

    return (
      <div className={"progress-container store-usage " + colour}>
        <div className={"progress-bar"} style={{width: percentage + '%'}}>{percentage}%</div>
      </div>
    );
  }

  /* LEVEL SWITCHER */
  renderLevels() {
    let levelSelector = null;

    if (this.state.levels) {

      let levels = [];
      for (var i = 0; i < this.state.levels; i++) {
        levels.push('Level ' + i);
      }

      levelSelector = (
        <div className="levels row -margin-bottom -margin-top -padding_2 pill -color-gray_dark">
          <div className="cell-sm">
            <h3 className="-margin-right_3 -margin-top_4 -line-reset -weight-medium -style-four -smaller">
              <span style={{color:"#ffffff"}}>Switch between levels:</span>
            </h3>
          </div>
          {
            levels.map(function(stacked_level, i) {
              let onClick = () => {
                this.setLevel(i);
              }
              let color = '-color-dark-grey';
              if (this.state.stacked_level == i) {
                color = '-color-blue_dark';
              }

              return(<div key={i} className="cell-sm-auto"><span className={"clickable -margin-right_3 -line-reset -weight-thin npill -style-four -smaller " + color} onClick={onClick}>{stacked_level}</span></div>)
            }.bind(this))
          }
        </div>
      );
    }

    return levelSelector;
  }

  setLevel(stacked_level) {
    let visibleFloorplanItems;

    visibleFloorplanItems = this.state.floorplanItems.filter((item) => {
      return item.stacked_level == stacked_level;
    });

    this.setState({ stacked_level: stacked_level, currentLevelItems: visibleFloorplanItems, visibleFloorplanItems: visibleFloorplanItems });
  }

  /* SUB LOCATION METHODS */
  renderChildren() {
    let toRender;

    if (this.props.writeMode) {
      toRender = this.renderChildrenFields();
    } else {
      toRender = this.renderChildrenList();
    }

    return (
      <div className="cell-sm-12">
        <div className="row">
          <div className="cell-sm -margin-bottom">
            <div className="row align-middle">
              <h3 className='-line-reset'>Sub Locations</h3>
              <span className="-weight-thin -line-reset_0 -margin-x_4">·</span>
              <span className="-weight-thin -weight-regular -size-medium -line-reset">{this.state.visibleFloorplanItems.length} of {this.state.floorplanItems.length}</span>
            </div>
          </div>
        </div>
        <div className="row">
          {toRender}
        </div>
      </div>
    )
  }

  renderChildrenFields() {
    return (
      <div className="cell-sm-12">
        {
          this.state.floorplanItems.map(function(el, i) {
            if (!this.props.selectedItemId || (this.props.selectedItemId && el.id == this.props.selectedItemId)) {
              return <StoreFloorplanItem key={el.id} item={el} setSelectedItem={this.setSelectedItem.bind(this)} selected={this.state.selectedItem && this.state.selectedItem.id == el.id} stacked_level={this.state.stacked_level} maxLevels={this.props.levels} updateItem={this.updateItem.bind(this)} removeItem={this.removeItem.bind(this)} storageTypes={this.props.storageTypes} />
            } else {
              return null;
            }

          }.bind(this))
        }
      </div>
    );
  }

  assetsUrl(location) {
    let joinToken = '?';
    if (this.props.assetsUrl.indexOf('?') > -1) {
      joinToken = "&";
    }
    return this.props.assetsUrl + joinToken + "view_assets=on&location[]=" + location.absolute_path_name
  }

  filterVisibleChildren(e) {
     let searchTerm = e.target.value.toLowerCase();
     let filteredItems = [];

     if (searchTerm.length > 2) {
       filteredItems = this.state.currentLevelItems.filter(function(item){
         return item.name.toLowerCase().indexOf(searchTerm) > -1;
       });


     } else {
       filteredItems = this.state.currentLevelItems;
     }

     this.setState({ visibleFloorplanItems: filteredItems });
  }

  renderChildrenList() {
    return (
      <div className="cell-sm-12">

        {!this.state.hideFloorplan && 
          <div className="row align-top -margin-bottom">
            <input className="form-control" placeholder="Search for a sub location..." onChange={this.filterVisibleChildren.bind(this)}/>
          </div>
        }
        {
          this.state.visibleFloorplanItems.map(function(el, i) {
            let colour = el.color;

            if (this.state.heatMap) {
              colour = this.heatMapColour(el);
            }

            return (
              <div key={el.id} className="row align-top">
                <div className="cell-sm-12">
                  <div className="row recursive-parent pill box-shadow -color-gray -padding align-middle recursive-level-0 -padding -margin-top_2 border-bottom">
                    <div className="cell-sm-auto">
                      <span className="-margin-right_2" style={{ backgroundColor: colour, display: 'block', width: '30px', height: '30px', borderRadius: '100%' }}></span>
                    </div>

                    <div className="-margin-right_2 cell-sm-4">
                      <p className="row align-middle -weight-regular align-middle -line-reset -size-big">
                        <a className="-variation-reset -color-black" href={el.view_url}>{el.name}</a>
                      </p>
                    </div>

                    <div className="cell-sm-2">
                      <h5 className="-weight-regular -size-medium -margin-bottom_3 -color-gray_dark">Assets</h5>
                      <h4 className="-weight-regular row">
                        {el.asset_count || 'None'}
                        {(el.asset_count || 0) > 0 &&
                          <a href={this.assetsUrl(el)} className="btn -icon -icon-only -variation-outlined -variation-round -color-gray -padding-x_4 -size-tiny -margin-left_3">
                            <span className="material-icons">search</span>
                          </a>
                        }
                      </h4>
                    </div>

                    <div className="cell-sm-auto -margin-right">
                      <h5 className="-weight-regular -size-medium -margin-bottom_3 -color-gray_dark">Sub Locations</h5>
                      <h4 className="-weight-regular row">{el.sub_location_count || 'None'}</h4>
                    </div>
                    <div className="cell-sm"></div>
                    {this.props.isOwner &&
                      <div className="cell-sm-auto">
                        <div className="row">
                          <a href={el.move_url} className="btn -icon -icon-only -variation-empty">
                            <span style={{ color: '#9a9a9a' }}>
                              <span className="material-icons">compare_arrows</span>
                            </span>
                          </a>
                          <a href={el.add_url} className="btn -icon -icon-only -variation-empty">
                            <span style={{ color: '#9a9a9a' }}>
                              <span className="material-icons">add</span>
                            </span>
                          </a>

                          <a href={el.edit_url} className="btn -icon -icon-only -variation-empty">
                            <span style={{ color: '#9a9a9a' }}>
                              <span className="material-icons">edit</span>
                            </span>
                          </a>

                          <a href={el.delete_url} data-method="delete" className="-state-hidden btn -icon -icon-only -variation-empty">
                            <span className="-color-red">
                              <span className="material-icons">delete_outline</span>
                            </span>
                          </a>
                        </div>
                      </div>
                    }
                  </div>
                </div>
              </div>
            );
          }.bind(this))
        }
      </div>
    )
  }

  render() {
    return (
      <div>

        {!this.state.hideFloorplan && this.renderAddToggle()}
        {!this.state.hideFloorplan && this.renderSelectedItemNotice()}
        {!this.state.hideFloorplan && this.renderHeatMapToggle()}
        <BaseFloorplan items={this.state.visibleFloorplanItems} heatMap={this.state.heatMap} heatMapColour={this.heatMapColour.bind(this)} selectedItem={this.state.selectedItem} height={this.props.height} floorplanWidth={this.props.floorplanWidth} floorplanHeight={this.props.floorplanHeight} planImage={this.props.planImage} onFloorplanClick={this.handleFloorplanClick.bind(this)} onItemClick={this.handleItemClick.bind(this)} hideFloorplan={this.state.hideFloorplan} />

        {this.renderLevels()}

        {this.renderChildren()}
        <Modal contentLabel="Example Modal" isOpen={this.state.modalIsOpen} onRequestClose={this.closeModal.bind(this)} style={customStyles}>
          <div className="task-modal-header">
            <h2 className="task-modal-title row -margin-bottom_2">
              <span className="cell-sm">{this.state.selectedItem.name}</span>
              <span className="cell-sm-auto"><span className="material-icons clickable" onClick={this.closeModal.bind(this)}>close</span></span>
            </h2>

            <div>
              
              {this.state.selectedItem.refresh_metrics_url &&
                <StoreMetrics refreshMetricsUrl={this.state.selectedItem.refresh_metrics_url} />
              }

              <div className="row -margin-top -padding-top_2">
                <div className="cell-sm"></div>
                <div className="cell-sm-8 -margin-bottom_2 -align-center">
                  <a href={this.assetsUrl(this.state.selectedItem)} target="_blank" className="btn view-assets">View Stored Assets</a>
                </div>
                <div className="cell-sm"></div>
              </div>

            </div>
          </div>
          
        </Modal>
      </div>
    );
  }
}


class StoreFloorplanItem extends React.Component {

  constructor(props) {
    super(props);

    this.state = { item: this.props.item };
  }

  updateField(event, field) {
    let item = this.props.item;
    item[field] = event.target.value;

    if (field == 'storage_type_id') {
      item['color'] = this.getStorageTypeColour(event.target.value);
    }

    this.props.updateItem(item);
  }

  toggleSelectedItem(event) {
    let selected = this.props.selected;

    this.setState({ selected: !selected }, function() {
      // If the item is already selected, clear it as the selected item
      let item = selected ? {} : this.state.item;
      this.props.setSelectedItem(item);
    });
  }

  handleRemove() {

    // If the item is NEW we don't care about setting it as 'toRemove' we just
    // need to remove it from the list of items, only persisted items need to remain
    // in the items list so we can create the _destroy hidden field for them
    if (this.state.item.newRecord) {
      this.props.removeItem(this.state.item);
    } else {
      this.updateField({ target: { value: true }}, 'toRemove');
    }
  }

  getStorageTypeColour(storageTypeId) {
    let colour = null;

    for (let i = 0; i < this.props.storageTypes.length; i++) {
      let st = this.props.storageTypes[i];

      if (st.id == storageTypeId) {
        colour = st.colour
        break;
      }
    }

    return colour;
  }

  renderVisible(){
    let idField = null;
    let style = null;

    if (!this.state.item.newRecord) {
      idField = <input type="hidden" name={"location[children_attributes][" + this.state.item.id + "][id]"} defaultValue={this.state.item.id} className="form-control" />
    }
    
    if (this.props.selected) {
      style = { backgroundColor: '#c8e0c8' };
    }

    return (
      <div key={this.state.item.id} className="row -margin-top_3 -padding-x_3 -padding-y_2" style={style}>
        <div className="-state-hidden cell-sm-auto -margin-right_3 align-center align-middle">
          <span className="material-icons">radio_button_unchecked</span>
        </div>

        <div className="cell-sm-1">
          <label className="cell-sm -padding-right_3">
            <p className="-variation-uppercase -line-reset -margin-bottom_3 -weight-regular -size-small">
              Colour
            </p>
            <input type="color" name={"location[children_attributes][" + this.state.item.id + "][color]"} value={this.state.item.color} onChange={(event) => this.updateField(event, 'color')} className="form-control" />
          </label>
        </div>

        <div className="cell-sm -margin-left_2">
          <label className="cell-sm -padding-right_3">
            <p className="-variation-uppercase -line-reset -margin-bottom_3 -weight-regular -size-small">
              Name
            </p>
            <input type="text" name={"location[children_attributes][" + this.state.item.id + "][name]"} defaultValue={this.state.item.name} onChange={(event) => this.updateField(event, 'name')} className="form-control" />
            <input type="hidden" name={"location[children_attributes][" + this.state.item.id + "][x]"} defaultValue={this.state.item.x} />
            <input type="hidden" name={"location[children_attributes][" + this.state.item.id + "][y]"} defaultValue={this.state.item.y} />
            <input type="hidden" name={"location[children_attributes][" + this.state.item.id + "][stacked_level]"} defaultValue={this.state.item.stacked_level} />
            <input type="hidden" name={"location[children_attributes][" + this.state.item.id + "][store]"} defaultValue={'true'} />
            {idField}
          </label>
        </div>

        <div className="cell-sm -margin-left_2">
          <label className="cell-sm -padding-right_3">
            <p className="-variation-uppercase -line-reset -margin-bottom_3 -weight-regular -size-small">
              Level
            </p>
            <input type="number" name={"location[children_attributes][" + this.state.item.id + "][stacked_level]"} defaultValue={this.state.item.stacked_level} min='0' max={this.props.maxLevels} onChange={(event) => this.updateField(event, 'stacked_level')} className="form-control" />
          </label>
        </div>

        <div className="cell-sm -margin-left_2">
          <label className="cell-sm -padding-right_3">
            <p className="-variation-uppercase -line-reset -margin-bottom_3 -weight-regular -size-small">
              Storage Type
            </p>
            <select name={"location[children_attributes][" + this.state.item.id + "][storage_type_id]"} defaultValue={this.state.item.storage_type_id} onChange={(event) => this.updateField(event, 'storage_type_id')} className="form-control">
              <option></option>
              {this.props.storageTypes.map(function(storage_type, i) {
                return (
                  <option key={storage_type.id} value={storage_type.id}>{storage_type.name}</option>
                )
              }.bind(this))}
            </select>
          </label>
        </div>

        <div className="cell-sm-auto -margin-left_2">
          <p className="-variation-uppercase -line-reset -margin-bottom_3 -weight-regular -size-small"></p>
          <span className={"clickable material-icons -margin-top_2" + (this.props.selected ? ' -color-green' : '')} onClick={this.toggleSelectedItem.bind(this)}>edit</span>
        </div>

        <div className="cell-sm-auto -margin-left_2">
          <p className="-variation-uppercase -line-reset -margin-bottom_3 -weight-regular -size-small"></p>
          <span className="clickable material-icons -margin-top_2 -color-red" onClick={this.handleRemove.bind(this)}>delete_outline</span>
        </div>
      </div>
    );
  }

  renderHidden() {
    let idField = null;

    if (!this.state.item.newRecord) {
      idField = <input type="hidden" name={"location[children_attributes][" + this.state.item.id + "][id]"} defaultValue={this.state.item.id} />
    }

    return (
      <div className="-state-hidden">
        <input type="hidden" name={"location[children_attributes][" + this.state.item.id + "][name]"} defaultValue={this.state.item.name} />
        <input type="hidden" name={"location[children_attributes][" + this.state.item.id + "][x]"} defaultValue={this.state.item.x} />
        <input type="hidden" name={"location[children_attributes][" + this.state.item.id + "][y]"} defaultValue={this.state.item.y} />
        <input type="hidden" name={"location[children_attributes][" + this.state.item.id + "][stacked_level]"} defaultValue={this.state.item.stacked_level} />
        <input type="hidden" name={"location[children_attributes][" + this.state.item.id + "][storage_type_id]"} defaultValue={this.state.item.storage_type_id} />
        {idField}
      </div>
    );
  }

  renderRemoveField() {
    let idField = null;
    if (!this.state.item.newRecord) {
      idField = <input type="hidden" name={"location[children_attributes][" + this.state.item.id + "][id]"} defaultValue={this.state.item.id} />
    }

    return(
      <div className="-state-hidden">
        {idField}
        <input type="hidden" name={"location[children_attributes][" + this.state.item.id + "][_destroy]"} value={true} />
        }
      </div>
    );
  }

  render() {

    let toRender = null;
    if (this.state.item.toRemove) {
      toRender = this.renderRemoveField();
    } else if (this.state.item.stacked_level == this.props.stacked_level) {
      toRender = this.renderVisible();
    } else {
      toRender = this.renderHidden();
    }

    return(
      <div>
        {toRender}    
      </div>
    );
  }
}


export default StoreFloorplan;
