import React from "react";
import request from 'superagent';
import './TaskItemStyle';

const CONFIRM_COMPLETED = "Have you completed this task?"
const CONFIRM_UNCOMPLETED = "You are marking this task as NOT completed, is this correct?"

class TaskItem extends React.Component {

  constructor(props) {
    super(props);
    this.state = { completed: (props.completedz || false)};
  }

  handleChange(e) {
    if (confirm(this.state.completed ? CONFIRM_UNCOMPLETED : CONFIRM_COMPLETED)) {
      const completed = !this.state.completed;
      this.setState({completed: completed})
      const req = request('PATCH', `/task_items/${this.props.id}`);
      req.set('X-CSRF-Token', document.querySelector('meta[name="csrf-token"]').content)
      req.send({completed: completed})
      req.end((err, res) => {
        if (res.ok) {
          this.setState({completed: res.body.completed})
        } else {
          this.setState({completed: !completed});
          alert(err.message);
        }
      })
    }
  }

  render() {
    const taskCompleted = this.state.completed ? 'taskCompleted': '';

    return (
      <div className={["row pill -color-gray_lighter -padding_2 align-top", taskCompleted].join(" ")}>
        <div className="cell-sm-auto -margin-right_2">
          <input type="checkbox" onChange={this.handleChange.bind(this)} checked={this.state.completed}/>
        </div>
        <div className="cell-sm">
          <h3 className="-weight-regular -line-reset-3 align-middle">{this.props.description}</h3>
          <div className="row _margin-x_2 -margin-top_2 align-top">
            <div className="cell-sm-3">
              <h6 className="-weight-light -variation-uppercase -line-reset">Scheduled Date</h6>
              <h4 className="-line-reset -margin-top_4 -weight-regular">{this.props.scheduled_date}</h4>
            </div>
            <div className="cell-sm-3">
              <h6 className="-weight-light -variation-uppercase -line-reset">Assigned To</h6>
              <h4 className="-line-reset -margin-top_4 -weight-regular">{this.props.assigned_to}</h4>
            </div>
            <div className="cell-sm-3">
              <h6 className="-weight-light -variation-uppercase -line-reset">Assigned By</h6>
              <h4 className="-line-reset -margin-top_4 -weight-regular">{this.props.assigned_by}</h4>
            </div>
          </div>
        </div>
      </div>
    )
  }

}

export default TaskItem;
