export default class ItemForm {
  constructor() { }

  initialize() {
    let pictureField = document.getElementById('item-image-field');

    if (pictureField) {
      pictureField.addEventListener('change', (event) => {
        this.changeEventHandler(event);
      });
    }
  }

  updateImage(event) {
    let imageField = event.target;

    if (imageField.files && imageField.files[0]) {
      let reader = new FileReader()

      reader.onload = function(e) {
        let holder = document.getElementById('item-image-holder');
        let oldImage = holder.getElementsByTagName('img')[0];
        holder.removeChild(oldImage);

        let newImage = new Image();
        newImage.className = '-size_x100 -size-equal -fit-cover -variation-rounded';
        newImage.src = e.target.result;
        
        holder.appendChild(newImage)
        
      };

      reader.readAsDataURL(imageField.files[0]);

    }
  }
}