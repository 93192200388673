import React from 'react';

const WAIT_INTERVAL = 1500;

class ItemSearch extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      searchResult: [],
      searchTerm: ''
    }
  }

  componentWillMount() {
    this.timer = null;
  }

  handleChange(e) {
    clearTimeout(this.timer);

    this.setState({ searchTerm: e.target.value });

    this.timer = setTimeout(this.handleSearch.bind(this), WAIT_INTERVAL);
  }

  handleSearch() {
    if (this.state.searchTerm.length > 3) {
      let request = require('request');
      request(this.props.url + ".json?code=" + this.state.searchTerm, function(error, response, body) {
        if (error) {

        } else {
          let parsed = JSON.parse(response.body);
          if (parsed.items) {
            this.setState({ searchResult: parsed.items });
          }
        }
      }.bind(this));
    }
  }

  renderItem(item, index) {

    return (
      <div key={"item-" + index} className="row item pill box-shadow -margin-top_2 align-top">
        <div className="cell-sm-auto item--image lightbox">
          <img src={item.photo} height='400px' className='-size_x12 -size-equal -variation-placeholder' />
          <img src={item.cat_item_photo} className='item-catalogue--image -size_x5 -variation-rounded -variation-placeholder -fit-cover' />
        </div>

        <div className="cell-sm -padding_2">
          <div className="row">
            <div className="cell-sm -margin-right_2">
              <h2 className="-weight-regular -line-reset align-middle">
                <span className="-weight-light -size-tiny -line-reset -variation-uppercase -spread">
                  {item.category.name}
                </span>
                <span className="-weight-light -size-tiny -line-reset -variation-uppercase -spread">
                  {item.type.name}
                </span>
               </h2>
               <span className='-margin-top_4 -variation-reset -weight-regular -size-big -color-black'>{item.code}</span>
            </div>

            <div className="cell-sm-auto">
              <div className="row align-middle item-actions">
                <a href={this.props.swapUrl + "?reuse_me_item_id=" + this.props.reuseMeItemId + "&new_item_id=" + item.id} className="btn -variation-empty -icon -icon-only">
                  <span className="-color-blue_dark">
                    <span className="material-icons">swap_horizontal_circle</span>
                  </span>
                </a>
              </div>
            </div>
          </div>
          <div className="row align-top -margin-top_2">
            <div className="cell-sm -padding-right_2">
              <span className="-size-medium -weight-regular -line-reset -margin-left_3">
                Status
              </span>

              <div className="row">
                <div className="cell-sm-auto">
                  <h4 className="-line-reset -weight-thin -margin-top_3 npill -style-one">
                    {item.status}
                  </h4>
                </div>
              </div>
            </div>
            <div className="cell-sm -padding-right_2">
              <span className="-size-medium -weight-regular -line-reset -margin-left_3">
                Condition
              </span>
              <div className="row">
                <div className="cell-sm-auto">
                  <h4 className="-line-reset -weight-thin -margin-top_3 npill -style-one">
                    <span style={{fontWeight: '500'}}>
                      {item.condition}
                    </span>
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    let showResults = this.state.searchResult.length > 0;
    return(
      <div>
        <div className="">
          <h3 className="-margin-top_2">Search for different asset</h3>
          <input type="text" className="form-control" onChange={this.handleChange.bind(this)} placeholder="Enter asset code..." />
        </div>

        {
          showResults &&
          <div className="pill -style-one faint-green -padding_2 -margin-top">
            <h2 className="-line-reset -color-green_dark">
              Search Results
            </h2>
            <h3>
              <span className="-weight-light -color-green_dark -size-large">Select an item to use instead</span>
            </h3>
            {
              this.state.searchResult.map(function(item, index) {
                return this.renderItem(item, index);
              }.bind(this))
            }
          </div>
        }
      </div>
    );
  }

}

export default ItemSearch;