import React from 'react';
import Select from 'react-select'

class SelectField extends React.Component {

   constructor(props) {
    super(props);

    this.state = { selected: this.props.selected };
  }

  customStyles() {
    return({
      option: function(base, state) {
        return({
          ...base,
          padding: 8
        });
      },
      control: function(base, state) {
        return({
          ...base,
          padding: '8px 8px 8px 10px',
          borderRadius: 0,
          border: '1px solid #9a9a9a',
          background: '#ffffff'
        });
      },
      multiValueLabel: function(base, state) {
        return({
          ...base,
          padding: '10px 8px 8px 10px',
          paddingLeft: 10,
          fontSize: '1em'
        });
      },
      multiValueRemove: function(base, state) {
        return({
          ...base,
          paddingRight: 8,
          paddingLeft: 8,
          ':hover': {
            color: '#721c24',
            backgroundColor: '#F9D7DD'
          }
        });
      },
      valueContainer: function(base, state) {
        return({
          ...base,
          padding: 0
        });
      }
    }); 
  }

  handleChange(selection) {
    // let newSelected = null;

    // if (this.props.isMultiple) {

    //   newSelected = [];

    //   for (let i = 0, l = selection.length; i < l; i++) {
    //     newSelected.push(selection[i].value);
    //   }

    // } else {
    //   newSelected = selection;
    // }

    this.setState({ selected: selection });
  }


  render () {
    return(
      <Select name={this.props.fieldName} styles={this.customStyles()} options={this.props.options} closeMenuOnSelect={!this.props.isMultiple} isMulti={this.props.isMultiple} placeholder="Search for locations..." defaultValue={this.state.selected} onChange={this.handleChange.bind(this)} />
    );
  }

}

export default SelectField;