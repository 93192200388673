import React from "react"
import PubSub from "pubsub-js"
import Modal from 'react-modal';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import request from 'superagent';

const TOPIC = "item.selector"

const customStyles = {
  content : {
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)',
    overflow              : 'visible',
    padding               : '0'
  }
};
Modal.setAppElement('body')

class TaskCreator extends React.Component {

  constructor(props) {
    super(props);
    
    this.state = {
      uuids: [],
      modalIsOpen: false,
      scheduledDate: moment(),
      dueDate: moment(),
      allowCreate: this.props.allowCreate || false
    };

    this.TOKEN = null;
    this.csrfToken = "";
  }

  componentDidMount(){
    this.csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    this.TOKEN = PubSub.subscribe(TOPIC, (msg, data) => {
	    if (data.selected) {
        this.setState({uuids: this.state.uuids.concat([data.uuid])});
      } else {
        this.setState({uuids: this.state.uuids.filter((uuid) => {
          return uuid !== data.uuid;
        })});
      }
    });
  }

  componentWillUnMount() {
    PubSub.unsubscribe(token);
  }

  openModal() {
    this.setState({modalIsOpen: true});
  }

  closeModal() {
    this.setState({modalIsOpen: false});
  }

  handleScheduledDateChange(date) {
    this.setState({ scheduledDate: date});
  }

  handleDueDateChange(date) {
    this.setState({ dueDate: date});
  }

  assetStatement() {
    if (this.state.uuids.length == 1) {
      return "for 1 asset";
    } else {
      return `for ${this.state.uuids.length} assets`;
    }
  }

  render() {
    let disabled = false;
    
    if (!this.state.allowCreate) {
      disabled = this.state.uuids.length == 0;
    }

    let button = null;

    if (this.props.taskPage) {
      button = (
        <button onClick={this.openModal.bind(this)} className={ `button-one color-purple` }>
          <i className="material-icons">add</i>
          <span className="-weight-bold -margin-left_3">New Work Request</span>
        </button>
      );
    } else {
      button = (
        <div onClick={this.openModal.bind(this)}>
          <p className="-line-reset_5 -margin-left_2 -color-white -weight-bold">New Work Request</p>
        </div>
        
        // <button onClick={this.openModal.bind(this)} disabled={disabled} className={ `-margin-left_3 button-two` }>
        //   Create a Task
        // </button>
      );
    }

    return (
      <div className="task-creator">
        {button}
        <Modal contentLabel="Example Modal" isOpen={this.state.modalIsOpen} onRequestClose={this.closeModal.bind(this)} style={customStyles}>
          <div className="task-modal-header">
            <h2 className="task-modal-title">Create a PlanME - Work Request <small className="title-sub">{this.assetStatement()}</small></h2>
          </div>
          <form className="form" style={{width: 540}} action="/planme" acceptCharset="UTF-8" method="post">
            <div className="task-modal-content">
              <input type="hidden" name="authenticity_token" value={this.csrfToken} />
              {this.state.uuids.map((uuid, index) => {
                return <input key={index} type="hidden" name="task_items[]" value={uuid}/>
              })}
              <input type="hidden" name="task[assigned_by_id]" value={this.props.assigned_by}/>

              <p className="-variation-uppercase -line-reset -margin-top_2 -margin-bottom_3 -weight-regular -size-small">Assign to</p>
              <select name="task[assigned_to_id]" className="form-control select">
                <option value="">None</option>
                {this.props.users.map((user, index) => {
                  return <option key={index} value={user.id}>{user.first_name} {user.last_name}</option>;
                })}
              </select>

              <p className="-variation-uppercase -line-reset -margin-top_2 -margin-bottom_3 -weight-regular -size-small">Description</p>
              <textarea name="task[description]" className="form-control" rows="6"></textarea>

              <div className="row">
                <div className="cell-sm-6">
                  <p className="-variation-uppercase -line-reset -margin-top_2 -margin-bottom_3 -weight-regular -size-small">Schedule</p>
                  <DatePicker selected={this.state.scheduledDate} onChange={this.handleScheduledDateChange.bind(this)} className="form-control" defaultValue={this.props.scheduled_date} />
                  <input type="hidden" name="task[scheduled_date]" value={moment(this.state.scheduledDate).format('YYYY-MM-DD')} />
                </div>

                <div className="cell-sm-6">
                  <p className="-variation-uppercase -line-reset -margin-top_2 -margin-bottom_3 -weight-regular -size-small">Due</p>
                  <DatePicker selected={this.state.dueDate} onChange={this.handleDueDateChange.bind(this)} className="form-control" defaultValue={this.props.due_date} />
                  <input type="hidden" name="task[due_date]" value={moment(this.state.dueDate).format('YYYY-MM-DD')} />
                </div>
              </div>

              <p className="-variation-uppercase -line-reset -margin-top_2 -margin-bottom_3 -weight-regular -size-small">Preliminary Approval</p>
              <input name="task[draft]" value="true" type="hidden" />
              <select name="task[reviewer_id]" className="form-control select">
                {this.props.reviewers.map((user, index) => {
                  return <option key={index} value={user.id}>{user.first_name} {user.last_name}</option>;
                })}
              </select>
            </div>
            <div className="row -margin-top align-middle align-right modal-buttons">
              <button onClick={this.closeModal.bind(this)} className="modal-button modal-button-cancel" >Cancel</button>
              <input type="submit" name="commit" value="Save" className="modal-button modal-button-save" data-disable-with="Save" />
            </div>
          </form>
        </Modal>
      </div>
    )
  }

}

export default TaskCreator;
