// Core
// 
import { Controller } from 'stimulus';

import React from 'react';
import ReactDOM from 'react-dom';

// Helpers
// 
import { addClass, removeClass, hasClass } from '../../../dom-helper';

// Plugins
// 
import TingleModal from 'tingle.js';
import iziToast from 'izitoast';

import { ReactSVGPanZoom } from 'react-svg-pan-zoom';
import { AutoSizer } from 'react-virtualized';

export default class extends Controller {
  static targets = ['floorplan', 'floorplanComponent'];

  floorplanTarget: any;

  floorplanComponentTarget: any;

  elementCounter: number = 0;

  floorplanItems = []
  floorplanCurrentItem: any = '';
  // floorplanWidth = 0;
  // floorplanHeight = 0;

  newCircle = true;

  canPlaceAnother: boolean = true;
  currentCircle: any;
  currentNodeID: number;

  model = 'item';
  mode = 'read';
  type = '';

  window: any = window;

  initialize() {
    console.log('this is initialized')
  }

  connect() {
    this.updateFloorplan(null, true)
  }

  showLocationOptions(event: any) {
    event.preventDefault();

    const modal = new TingleModal.modal({
      footer: false,
      stickyFooter: false,
      closeMethods: ['overlay', 'button', 'escape'],
      closeLabel: 'Close',
      cssClass: [],
      onOpen: () => { },
      onClose: () => { },
      beforeClose: () => { return true }
    });

    modal.setContent(document.getElementById('modalContent').innerHTML);
    modal.open();
  }

  create(selector, args) {
    const attributes = selector.dataset;

    this.mode = attributes.viewMode;
    this.type = args['type'] || attributes.type;
    this.mode = args['mode'] || attributes.viewMode;
    this.model = attributes.model || args['model'];

    // let plan, floorplanWidth, floorplanHeight;

    if (attributes.items.length > 1) { 
      this.floorplanItems = JSON.parse(attributes.items)
    }

    if (attributes.item) {
      this.floorplanCurrentItem = JSON.parse(attributes.item)
    }

    const floorplan: any = {
      url: '',
      height: '',
      width: ''
    }

    if (args['floorplan'] && args['floorplanURL'] === false) {
      this.window.URL = this.window.URL || this.window.webkitURL;
    
      const useWindowBLOB = false && this.window.URL;
      let that = this;

      let reader = new FileReader();

      reader.onload = function() {
        let image = new Image();
      
        image.onload = function() {
          floorplan['url']    = image.src;
          floorplan['width']  = image.width;
          floorplan['height'] = image.height;

          // Free up memory
          // 
          if (useWindowBLOB) {
            that.window.URL.revokeObjectURL(image.src)
          }
          
          that.render(selector, floorplan);
        }

        image.src = (useWindowBLOB ? that.window.URL.createObjectURL(args['floorplan']) : reader.result);          
      }

      reader.readAsDataURL(args['floorplan']);
    
    } else {
      floorplan['url']    = attributes.plan;
      floorplan['width']  = Number(attributes.width);
      floorplan['height'] = Number(attributes.height);

      this.render(selector, floorplan)
    }
  }

  render(selector, floorplan) {
    console.log('rendered')

    ReactDOM.render(
      <div style={{ width: '100%', height: '100%' }}>
        <AutoSizer>
          {(
            ({ width, height }) => width === 0 || height === 0 ? null : (
              <ReactSVGPanZoom width={ width } height={ height } x='0' y='0' onClick={ event => this.addNewChildLocation(event) } toolbarPositior={ 'none' } tool={ 'auto' }>
                <svg width={ floorplan['width'] } height={ floorplan['height'] }>
                  <image href={ floorplan['url'] } x='0' y='0' width={ floorplan['width'] } height={ floorplan['height'] } />
                    <svg id='floorplanItemsSVG' width={ floorplan['width'] } height={ floorplan['height'] }>
                      {
                        this.floorplanItems.map((el, i) =>
                          <circle id={ el.id } key={ i } cx={ el.x } cy={ el.y } r='10' fill={ el.color || ( this.floorplanCurrentItem.id == el.id ? '#6DA9ED' : '#454F5B') } onClick={ event => this.onClick(event, el) } />
                        )
                      }
                    </svg>
                </svg>
              </ReactSVGPanZoom>
            )
          )}
        </AutoSizer>
      </div>,

      selector,
    )
  }

  addNewChildLocation(event: any) {
    let src: any;

    console.log('place', this.canPlaceAnother)

    if (this.canPlaceAnother) {
      if (event.SVGViewer) {
        src = document.querySelectorAll('a[data-target="#locations"]')[0]
      } else {
        event.preventDefault();
        src = event.srcElement;
      }

      const id:     number = (new Date().getTime() + this.elementCounter++),
            target: string = src.dataset.target,
            html:   any = src.dataset.html;

      this.currentNodeID = id;

      const row: any = document.createElement('div');
            row.innerHTML = html.toString().replace(/child_index_replace_with_js/g, id);

      addClass(row, 'row');
      // addClass(row, '-margin-top_3');
      // addClass(row, `container-for-${ id }`);

      document.querySelectorAll(target)[0].insertBefore(row, document.querySelectorAll(target)[0].firstChild);

      const colour = '#'+( 0x1000000 + ( Math.random() ) * 0xffffff).toString(16).substr(1, 6);

      const colourInput: any = document.getElementById(`location_children_attributes_${ id }_color`);
            colourInput.value = colour;

      const x: any = document.getElementById(`location_children_attributes_${ id }_x`);
            x.value = event.x;

      const y: any = document.getElementById(`location_children_attributes_${ id }_y`);
            y.value = event.y;

      if (event.SVGViewer) {
        this.setFloorplanNode(event, colour);
        this.canPlaceAnother = false;
      }
    } else {
      this.updateFloorplanNode(event);
    }
  }

  updateFloorplan(event: any, arg = false) {
    try {
      const floo: any = document.querySelectorAll('[data-floorplan]')[0];

      if (floo) {
        if (arg) {
          this.create(floo, { floorplan: floo.dataset.plan, floorplanURL: true })
        } else {
          this.create(floo, { floorplan: this.floorplanTarget.files[0], floorplanURL: false })
        }
      }
    } catch(e) {
      console.log(e)
    }
  }

  setFloorplanNode(event: any, colour?: string) {
    const svgns = 'http://www.w3.org/2000/svg',
          svg = event.SVGViewer.ownerDocument,
          circle = svg.createElementNS(svgns, 'circle');

    circle.setAttributeNS(null, 'r', 10);
    circle.setAttributeNS(null, 'id', this.currentNodeID);
    circle.setAttributeNS(null, 'fill', colour || '#EA5455');

    circle.setAttributeNS(null, 'cx', String(event.x.toFixed(2)));
    circle.setAttributeNS(null, 'cy', String(event.y.toFixed(2)));
        
    this.currentCircle = circle;

    document.getElementById('floorplanItemsSVG').appendChild(circle);
  }

  updateFloorplanNode(event: any) {
    this.currentCircle.setAttributeNS(null, 'cx', String(event.x.toFixed(2)));
    this.currentCircle.setAttributeNS(null, 'cy', String(event.y.toFixed(2)));

    const x: any = document.getElementById(`location_children_attributes_${ this.currentNodeID }_x`);
          x.value = event.x;

    const y: any = document.getElementById(`location_children_attributes_${ this.currentNodeID }_y`);
          y.value = event.y;
  }

  finaliseFloorplanNode() {
    const input: any = document.querySelectorAll(`input[id="${ this.currentNodeID }"]`)[0];

    console.log(input)
    console.log(this.currentNodeID)
    console.log(this.currentCircle)

    if (input.value.length) {
      const check:any = document.querySelectorAll(`#set-${ this.currentNodeID }`)[0];
            check.style.display = 'none';

      const activate:any = document.querySelectorAll(`#activate-${ this.currentNodeID }`)[0];
            activate.style.display = 'block';

      iziToast.success({
        title: 'Location Saved! You can now place another one.',
        message: ''
      })

      this.currentCircle = null;
      this.currentNodeID = null;
      this.canPlaceAnother = true;
    } else {
      iziToast.error({
        title: 'Name your Location before placing another one!',
        message: ''
      })
    }
  }

  unsetFloorplanNode(event: any) {
    const id: any = event.target.parentElement.dataset.id;
  
    document.querySelectorAll(`.container-for-${ id }`)[0].remove()
    document.querySelectorAll(`circle[id="${ id }"]`)[0].remove()
    
    this.currentCircle = null;
    this.currentNodeID = null;
    this.canPlaceAnother = true;
  }

  setCurrentNode(event: any) {
    if (this.currentNodeID && this.currentCircle) {
      iziToast.error({
        title: 'Finish changing your previous Location before activating another one.',
        message: ''
      })
    } else {
      this.currentNodeID = event.target.parentElement.dataset.id;
      this.currentCircle = document.querySelectorAll(`circle[id="${ this.currentNodeID }"]`)[0];
      this.canPlaceAnother = false;

      const check:any = document.querySelectorAll(`#set-${ this.currentNodeID }`)[0];
            check.style.display = 'block';

      const activate:any = document.querySelectorAll(`#activate-${ this.currentNodeID }`)[0];
            activate.style.display = 'none';

      iziToast.info({
        title: 'Current Node changed. You can no change its location.',
        message: ''
      })

      console.log(this.currentNodeID)
      console.log(this.currentCircle)
      console.log(this.canPlaceAnother)
    }
  }

  onClick(event, element = null) {
    console.log(event)
    console.log(element)
  }
}