import React from 'react';
import PubSub from 'pubsub-js';

class RoleSlider extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      checked: this.props.checked
    };
  }

  componentDidMount(){
    this.TOKEN = PubSub.subscribe("roles-" + this.props.klass, (msg, data) => {
      if (!this.props.master) {
        this.setState({ checked: data.checked });
      }
      
    });
  }

  componentWillUnMount() {
    PubSub.unsubscribe(this.TOKEN);
  }

  toggleChecked() {
    let newCheckedState = !this.state.checked;
    
    this.setState({ checked: newCheckedState }, function(){
      if (this.props.master) {
        PubSub.publish("roles-" + this.props.klass, {
          checked: newCheckedState
        });
      }
    }.bind(this));
  }

  dummyChange() {
    
  }


  render() {
    return (
      <div className="cell-sm-auto align-center">
        <input type="checkbox" name={this.props.name} id={this.props.identifier} value={this.props.value} className="hidden-checkbox" onChange={this.dummyChange.bind(this)} checked={this.state.checked} />
        <label htmlFor={this.props.identifier} className="fake-checkbox nudge-right" style={{ 'verticalAlign': 'sub' }}>
          <span className="material-icons checked" onClick={this.toggleChecked.bind(this)}>toggle_on</span>
          <span className="material-icons not-checked" onClick={this.toggleChecked.bind(this)}>toggle_off</span>
        </label>
      </div>
    );
  }

}

export default RoleSlider;

