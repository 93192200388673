import React from 'react';

class FancyCheckbox extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      checked: this.props.checked
    };
  }


  toggleChecked() {
    let newCheckedState = !this.state.checked;
    this.setState({ checked: newCheckedState });
  }

  dummyChange() {
    
  }


  render() {
    return (
      <div className="cell-sm-auto align-center">
        <input type="hidden" name={this.props.name} value={this.state.checked ? 1 : 0} onChange={this.dummyChange.bind(this)} />
        <label className="nudge-right" style={{ 'verticalAlign': 'sub' }}>
          {this.state.checked &&
            <span className="material-icons checked -size-massive" onClick={this.toggleChecked.bind(this)}>toggle_on</span>
          }
          {!this.state.checked &&
            <span className="material-icons not-checked -size-massive" onClick={this.toggleChecked.bind(this)}>toggle_off</span>
          }
        </label>
      </div>
    );
  }

}

export default FancyCheckbox;

